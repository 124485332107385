@import "~@angular/material/theming";

$font-family1: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
$font-family2: "Cabin", sans-serif;
$main-color: #12b6df;
$main-color2: #20aef9;
$optional-color: #4a6f8a;
$white-color: #ffffff;
$black-color: #000000;
$transition: 0.5s;
$font-size: 16px;

body {
    padding: 0;
    margin: 0;
    font: {
        family: $font-family1;
        size: $font-size;
    }
    overflow-x: hidden !important;
}
.container {
    max-width: 1140px;
}
img {
    max-width: 100%;
    height: auto;
}
p {
    // color: $optional-color;
    line-height: 1.8;
    margin-bottom: 15px;
    font-size: $font-size;

    &:last-child {
        margin-bottom: 0;
    }
}
.img{
    max-width: unset;
}
:focus {
    outline: 0 !important;
}
a {
    color: $black-color;
    text-decoration: none;
    transition: $transition;

    &:hover {
        color: $main-color;
        text-decoration: none;
    }
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family2;
    color: $black-color;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    }
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.bg-F7F9FB {
    background-color: #f7f9fb;
}
.bg-f7fafd {
    background-color: #f7fafd;
}
/*section-title*/
.section-title {
    text-align: center;
    position: relative;
    margin-top: 20px !important;
    margin: {
        bottom: 60px;
        top: -5px;
    }
    .sub-title {
        display: block;
        color: $main-color;
        text-transform: uppercase;
        margin-bottom: 8px;
        font: {
            style: italic;
            weight: 600;
            size: 14px;
        }
    }
    h2 {
        margin-bottom: 0;
        position: relative;
        padding-bottom: 13px;
        font: {
            size: 40px;
            weight: 700;
        }
        &::before {
            position: absolute;
            left: 0;
            right: 0;
            width: 55px;
            height: 3.5px;
            background-color: $main-color;
            content: "";
            bottom: 0;
            margin: {
                left: auto;
                right: auto;
            }
        }
    }
    .section-title-btn {
        text-transform: uppercase;
        display: inline-block;
        margin-top: 15px;
        padding-right: 22px;
        position: relative;
        font: {
            size: 14px;
            weight: 600;
        }
        i {
            position: absolute;
            right: 0;
            top: 1px;
            font-size: 20px;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            background-color: $main-color;
            transition: $transition;
        }
        &:hover {
            &::before {
                transform: scaleX(1);
            }
        }
    }
    &.text-start {
        h2 {
            &::before {
                margin: {
                    left: 0;
                    right: 0;
                }
            }
        }
        .section-title-btn {
            position: absolute;
            right: 0;
            bottom: 0;
            margin-top: 0;
        }
    }
}
/*default-btn*/
.default-btn {
    display: inline-block;
    background-color: $main-color;
    color: $white-color;
    transition: $transition;
    border: none;
    padding: 14px 30px 12px;
    border-radius: 8px;
    font: {
        size: $font-size;
        weight: 600;
    }
    &:hover {
        background-color: $main-color2;
        color: $white-color;
    }
}
.optional-btn {
    display: inline-block;
    background-color: transparent;
    position: relative;
    z-index: 1;
    color: $black-color;
    transition: $transition;
    border: none;
    padding: 11.5px 30px 12px;
    border-radius: 30px;
    font: {
        size: $font-size;
        weight: 600;
    }
    &::before {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        content: "";
        transition: $transition;
        position: absolute;
        border: 1px solid $black-color;
        border-radius: 30px;
        z-index: -1;
    }
    &:hover {
        background-color: $main-color2;
        color: $white-color;

        &::before {
            border-color: $main-color;
        }
    }
}
/*form-control*/
.form-control {
    fill: rgba(242, 245, 255, 0.2) !important;
    height: 48px;
    border-radius: 0;
    box-shadow: unset !important;
    padding: 0 0 0 15px;
    border: 1px solid #f5f5f5;
    color: $black-color;
    font-size: $font-size;
    transition: $transition;

    &:focus {
        border-color: $main-color;
        background-color: $white-color;

        &::placeholder {
            color: white;
        }
    }
    &::placeholder {
        transition: $transition;
    }
}
textarea.form-control {
    padding-top: 15px;
    height: auto !important;
}

/*================================================
Header Area CSS
=================================================*/
.header-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 999;
}
.top-header {
    background-color: $main-color;
    padding: {
        top: 12px;
        bottom: 12px;
    }
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        }
    }
}
.header-contact-info {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
        display: inline-block;
        color: $white-color;
        margin-right: 29px;
        position: relative;
        font: {
            weight: 600;
            size: 14px;
        }
        &::before {
            position: absolute;
            right: -15px;
            top: 5px;
            width: 1px;
            content: "";
            height: 12px;
            background-color: $white-color;
        }
        a {
            display: inline-block;
            color: $white-color;
            position: relative;

            &:hover {
                color: $white-color;
            }
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background: $white-color;
                transition: 0.5s;
            }
            &:hover {
                &::before {
                    width: 100%;
                }
            }
        }
        &:last-child {
            margin-right: 0;

            &::before {
                display: none;
            }
        }
        .language-switcher {
            position: relative;

            .dropdown-toggle {
                padding: 0;
                border: none;
                background-color: transparent;
                color: $white-color;
                font: {
                    weight: 600;
                    size: 14px;
                }
                &::after {
                    display: none;
                }
                img {
                    width: 35px;
                    border-radius: 3px;
                    border: 2px solid $white-color;
                    position: relative;
                    top: -2px;
                }
                span {
                    display: inline-block;
                    margin-left: 9px;

                    i {
                        position: relative;
                        font-size: 20px;
                        top: 4px;
                        margin-left: -3px;
                    }
                }
            }
            .dropdown-menu {
                padding: 15px;
                float: unset;
                border: none;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                border-radius: 0;
                display: block;
                margin-top: 12px;
                opacity: 0;
                visibility: visible;
                transition: $transition;
                transform: scaleX(0);

                .dropdown-item {
                    padding: 0;
                    color: $black-color;
                    margin-bottom: 10px;
                    font: {
                        size: 14px;
                        weight: 600;
                    }
                    &::before {
                        display: none;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    img {
                        width: 40px;
                        border-radius: 5px;
                        border: 3px solid $white-color;
                    }
                    span {
                        display: inline-block;
                        margin-left: 7px;
                    }
                    &:hover,
                    &:focus {
                        background-color: white !important;
                    }
                    &:active,
                    &.active {
                        color: $black-color;
                        background-color: white !important;
                    }
                }
                &.active {
                    opacity: 1;
                    visibility: visible;
                    transform: scaleX(1);
                }
            }
        }
    }
}
.top-header-discount-info {
    text-align: center;

    p {
        margin-bottom: 0;
        line-height: initial;
        font-size: 14px;
        color: $white-color;

        a {
            display: inline-block;
            font-weight: 700;
            position: relative;
            color: $white-color;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background: $white-color;
                transition: 0.5s;
            }
            &:hover {
                color: $white-color;

                &::before {
                    width: 100%;
                }
            }
        }
    }
}
.header-top-menu {
    text-align: end;
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
        display: inline-block;
        color: $white-color;
        font-weight: 600;
        margin-right: 29px;
        position: relative;
        font-size: 14px;

        &::before {
            position: absolute;
            right: -15px;
            top: 6px;
            width: 1px;
            content: "";
            height: 12px;
            background-color: $white-color;
        }
        i {
            position: absolute;
            left: 0;
            top: 3px;
            font-size: 16px;
        }
        a {
            display: inline-block;
            color: $white-color;
            padding-left: 22px;
            position: relative;

            &:hover {
                color: $white-color;
            }
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
                background: $white-color;
                transition: 0.5s;
            }
            &:hover {
                &::before {
                    width: 100%;
                }
            }
        }
        &:last-child {
            margin-right: 0;

            &::before {
                display: none;
            }
        }
    }
}
.louise-responsive-nav {
    display: none;
}
.louise-nav {
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    .container-fluid {
        padding: {
            left: 45px;
            right: 45px;
        }
    }
    .navbar[layout-dir="rtl"] {
        margin-right: -2px;
    }
    .navbar {
        width: 85%;
        margin: auto;
        margin-left: -2%;
        margin-right: -2px;
        position: inherit;
        padding: {
            left: 0;
            right: 0;
        }
        .navbar-brand {
            font-size: inherit;
            line-height: 1;
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: {
                left: auto;
                right: auto;
            }
            .nav-item {
                position: relative;

                a {
                    color: $white-color;
                    font: {
                        size: 16px;
                        weight: 600;
                    }
                    padding: {
                        left: 0;
                        right: 0;
                        top: 20px;
                        bottom: 20px;
                    }
                    margin: {
                        left: 13px;
                        right: 13px;
                    }
                    i {
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin: {
                            left: -3px;
                            right: -3px;
                        }
                    }
                    &:hover,
                    &:focus,
                    &.active {
                        color: $white-color;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover,
                &.active {
                    a {
                        color: $white-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 70px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    transition: all 0.2s ease-in-out;
                    padding: 0;
                    transform: scaleX(0);

                    li {
                        a {
                            padding: 13px 20px 11px;
                            margin: 0;
                            position: relative;
                            display: block;
                            color: $optional-color;
                            border-bottom: 1px dashed #e5e5e5;
                            font: {
                                size: 15px;
                                weight: 600;
                            }
                            i {
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                font-size: 20px;
                                transform: translateY(-50%);
                                right: 10px;
                            }
                            &:hover,
                            &:focus,
                            &.active {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            left: -250px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;
                            transform: scaleY(0);

                            li {
                                a {
                                    color: $optional-color;

                                    &:hover,
                                    &:focus,
                                    &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: 250px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
                                    transform: scaleY(0);

                                    li {
                                        a {
                                            color: $optional-color;

                                            &:hover,
                                            &:focus,
                                            &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;
                                            transform: scaleY(0);

                                            li {
                                                a {
                                                    color: $optional-color;

                                                    &:hover,
                                                    &:focus,
                                                    &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: 250px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;
                                                    transform: scaleY(0);

                                                    li {
                                                        a {
                                                            color: $optional-color;

                                                            &:hover,
                                                            &:focus,
                                                            &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;
                                                            transform: scaleY(
                                                                0
                                                            );

                                                            li {
                                                                a {
                                                                    color: $optional-color;

                                                                    &:hover,
                                                                    &:focus,
                                                                    &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: 250px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                                                    transform: scaleY(
                                                                        0
                                                                    );

                                                                    li {
                                                                        a {
                                                                            color: $optional-color;

                                                                            &:hover,
                                                                            &:focus,
                                                                            &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        transform: scaleY(
                                                                            1
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                transform: scaleY(
                                                                    1
                                                                );
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        transform: scaleY(1);
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                transform: scaleY(1);
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        transform: scaleY(1);
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                transform: scaleY(1);
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        transform: scaleX(1);
                    }
                }
                &.megamenu {
                    position: inherit;

                    .container {
                        display: block;
                        flex-wrap: unset;
                        align-items: unset;
                        justify-content: unset;
                    }
                    .dropdown-menu {
                        width: 100%;
                        margin-top: 8px;
                        position: absolute;
                        top: auto;
                        left: 0;
                        padding: 30px 0;
                        transform: unset !important;

                        .submenu-title {
                            color: $black-color;
                            position: relative;
                            border-bottom: 1px solid #eeeeee;
                            padding-bottom: 8px;
                            text-transform: uppercase;
                            margin: {
                                bottom: 20px;
                                top: 25px;
                            }
                            font: {
                                size: 15.5px;
                                weight: 600;
                            }
                            &::before {
                                width: 30px;
                                height: 1px;
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: -1px;
                                background-color: $main-color;
                            }
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                        a {
                            border-bottom: none !important;
                        }
                        .megamenu-submenu {
                            li {
                                a {
                                    margin-top: 15px;
                                    color: $optional-color;
                                    padding: 0;
                                    font: {
                                        weight: 600;
                                    }
                                    &:hover,
                                    &.active {
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                        .partner-slides {
                            margin-top: 30px;
                            border: {
                                top: 1px solid #eeeeee;
                                bottom: 1px solid #eeeeee;
                                left: 1px solid #eeeeee;
                            }
                        }
                    }
                }
            }
        }
        .others-option {
            .option-item {
                margin-left: 15px;
                position: relative;
                z-index: 99;

                &:first-child {
                    margin-left: 0;
                }
                .search-btn-box {
                    position: relative;
                    top: 5px;

                    .search-btn {
                        font-size: 25px;
                        cursor: pointer;
                        color: $white-color;
                        transition: $transition;
                    }
                }
                .cart-btn {
                    font-size: 25px;
                    top: 3px;
                    position: relative;
                    margin-right: 5px;
                    line-height: 1;

                    a {
                        display: inline-block;
                        position: relative;
                        color: $white-color;

                        span {
                            position: absolute;
                            right: -4px;
                            top: -4px;
                            width: 14px;
                            height: 14px;
                            text-align: center;
                            line-height: 13.9px;
                            border-radius: 50%;
                            color: $white-color;
                            background-color: $main-color;
                            font: {
                                size: 10px;
                                weight: 600;
                            }
                        }
                    }
                }
                .default-btn {
                    border: 1.5px solid $white-color;
                    background-color: transparent;
                    color: $white-color;
                    position: relative;
                    padding: {
                        top: 9px;
                        bottom: 10.5px;
                        left: 45px;
                        right: 20px;
                    }
                    i {
                        position: absolute;
                        font-size: 20px;
                        left: 20px;
                        top: 10px;
                    }
                    &:hover {
                        background-color: $white-color;
                        border-color: $white-color;
                        color: $main-color;
                    }
                }
            }
        }
    }
}
.navbar-area {
    &.is-sticky {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        background-color: $main-color;
        transition: $transition;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.1);

        &.scrollUp {
            transform: translateY(-95px);
            opacity: 0;
            visibility: hidden;
        }
        .louise-nav {
            border-bottom: none;
        }
    }
    &.navbar-style-two {
        .louise-nav {
            border-bottom: none;

            .navbar {
                .navbar-nav {
                    margin: {
                        left: auto;
                        right: 25px;
                    }
                    .nav-item {
                        a {
                            color: $black-color;

                            &:hover,
                            &:focus,
                            &.active {
                                color: $main-color;
                            }
                        }
                        &:hover,
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            li {
                                a {
                                    color: $optional-color;

                                    &:hover,
                                    &:focus,
                                    &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    li {
                                        a {
                                            color: $optional-color;

                                            &:hover,
                                            &:focus,
                                            &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            li {
                                                a {
                                                    color: $optional-color;

                                                    &:hover,
                                                    &:focus,
                                                    &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    li {
                                                        a {
                                                            color: $optional-color;

                                                            &:hover,
                                                            &:focus,
                                                            &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            li {
                                                                a {
                                                                    color: $optional-color;

                                                                    &:hover,
                                                                    &:focus,
                                                                    &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    li {
                                                                        a {
                                                                            color: $optional-color;

                                                                            &:hover,
                                                                            &:focus,
                                                                            &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                        .dropdown-menu {
                                                                            li {
                                                                                a {
                                                                                    color: $optional-color;

                                                                                    &:hover,
                                                                                    &:focus,
                                                                                    &.active {
                                                                                        color: $main-color;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &.active {
                                                                            a {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                        &.megamenu {
                            .dropdown-menu {
                                .megamenu-submenu {
                                    li {
                                        a {
                                            color: $optional-color;

                                            &:hover,
                                            &.active {
                                                color: $main-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .others-option {
                    .option-item {
                        .search-btn-box {
                            .search-btn {
                                color: $black-color;

                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        .cart-btn {
                            a {
                                color: $black-color;

                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        .default-btn {
                            border-width: 1px;
                            border-color: $main-color;
                            background-color: transparent;
                            color: $main-color;

                            &:hover {
                                background-color: $main-color;
                                border-color: $main-color;
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
        &.is-sticky {
            background-color: $white-color;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
    .louise-responsive-nav {
        display: block;

        .louise-responsive-menu {
            position: relative;

            &.mean-container {
                .mean-nav {
                    margin-top: 50px;

                    ul {
                        font-size: 14px;

                        li {
                            a {
                                &.active {
                                    color: $main-color;
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    height: 295px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);

                    .nav-item {
                        &.megamenu {
                            .dropdown-menu {
                                .nav-item {
                                    overflow: hidden;

                                    .container {
                                        padding: {
                                            left: 0;
                                            right: 0;
                                        }
                                    }
                                    .row {
                                        display: block;
                                        flex-wrap: unset;
                                        margin: {
                                            left: 0;
                                            right: 0;
                                            top: -20px;
                                        }
                                        .col {
                                            flex-basis: unset;
                                            flex-grow: unset;
                                            max-width: 100%;
                                            padding: {
                                                left: 0;
                                                right: 0;
                                                top: 20px;
                                            }
                                            &:last-child {
                                                margin-bottom: 15px;
                                            }
                                        }
                                    }
                                    a {
                                        width: 100%;
                                        float: unset;
                                        display: block;
                                    }
                                    .partner-slides {
                                        margin-top: 0;
                                        border: {
                                            top: none;
                                            bottom: none;
                                            left: none;
                                        }
                                        .partner-item {
                                            a {
                                                display: block !important;
                                                padding: 20px !important;
                                                width: auto !important;
                                            }
                                        }
                                    }
                                }
                                .submenu-title {
                                    color: $black-color;
                                    position: relative;
                                    border-bottom: 1px solid #eeeeee;
                                    padding-bottom: 8px;
                                    text-transform: uppercase;
                                    margin: {
                                        bottom: 5px;
                                        top: 25px;
                                        left: 20px;
                                        right: 20px;
                                    }
                                    font: {
                                        size: 13.5px;
                                        weight: 600;
                                    }
                                    &::before {
                                        width: 30px;
                                        height: 1px;
                                        content: "";
                                        position: absolute;
                                        left: 0;
                                        bottom: -1px;
                                        background-color: $main-color;
                                    }
                                    &:first-child {
                                        margin-top: 5px;
                                    }
                                }
                                a {
                                    border-bottom: none !important;
                                }
                                .mean-expand {
                                    display: none !important;
                                }
                                .megamenu-submenu {
                                    width: auto;
                                    overflow: hidden;
                                    display: block !important;
                                    padding: {
                                        left: 20px;
                                        right: 20px;
                                    }
                                    li {
                                        a {
                                            border: none;
                                            margin-top: 15px;
                                            color: #666666;
                                            padding: 0;
                                            font: {
                                                weight: 400;
                                            }
                                            &:hover,
                                            &.active {
                                                color: $main-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .others-option {
                    position: absolute;
                    right: 45px;
                    top: 2px;

                    .option-item {
                        margin-left: 10px;

                        &:first-child {
                            margin-left: 0;
                        }
                        .search-btn-box {
                            position: relative;
                            top: 5px;

                            .search-btn {
                                font-size: 25px;
                                cursor: pointer;
                                color: $white-color;
                                transition: $transition;
                            }
                        }
                        .cart-btn {
                            font-size: 25px;
                            top: 3px;
                            position: relative;
                            margin-right: 5px;
                            line-height: 1;

                            a {
                                display: inline-block;
                                position: relative;
                                color: $white-color;

                                span {
                                    position: absolute;
                                    right: -4px;
                                    top: -4px;
                                    width: 14px;
                                    height: 14px;
                                    text-align: center;
                                    line-height: 13.9px;
                                    border-radius: 50%;
                                    color: $white-color;
                                    background-color: $main-color;
                                    font: {
                                        size: 10px;
                                        weight: 600;
                                    }
                                }
                            }
                        }
                        .default-btn {
                            display: none;
                        }
                    }
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: $white-color;
                    margin-top: 10px !important;

                    span {
                        background: $white-color;
                    }
                }
            }
        }
        .logo {
            position: relative;
            width: 50%;
            z-index: 999;
        }
    }
    .louise-nav {
        display: none;
    }
    .navbar-area {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        padding: {
            top: 15px;
            bottom: 15px;
        }
        &.is-sticky {
            border-bottom: none;
        }
        &.navbar-style-two {
            border-bottom: none;

            .louise-responsive-nav {
                .mean-container {
                    a {
                        &.meanmenu-reveal {
                            color: $black-color;

                            span {
                                background: $black-color;
                            }
                        }
                    }
                }
                .louise-responsive-menu {
                    &.mean-container {
                        .others-option {
                            .option-item {
                                .search-btn-box {
                                    .search-btn {
                                        color: $black-color;
                                    }
                                }
                                .cart-btn {
                                    a {
                                        color: $black-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Search Overlay CSS
=================================================*/
.search-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    .search-overlay-layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(100%);

        &:nth-child(1) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.3s ease-in-out 0s;
        }
        &:nth-child(2) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: all 0.3s ease-in-out 0.3s;
        }
        &:nth-child(3) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            transition: all 0.9s ease-in-out 0.6s;
        }
    }
    .search-overlay-close {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 50px;
        z-index: 2;
        text-align: center;
        cursor: pointer;
        padding: 10px;
        transition: all 0.9s ease-in-out 1.5s;
        opacity: 0;
        visibility: hidden;

        .search-overlay-close-line {
            width: 100%;
            height: 3px;
            float: left;
            margin-bottom: 5px;
            background-color: $white-color;
            transition: all 500ms ease;

            &:nth-child(1) {
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                margin-top: -7px;
                transform: rotate(-45deg);
            }
        }
        &:hover {
            .search-overlay-close-line {
                background: $main-color;
                transform: rotate(180deg);
            }
        }
    }
    .search-overlay-form {
        transition: all 0.9s ease-in-out 1.4s;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 2;
        max-width: 500px;
        width: 500px;

        form {
            position: relative;

            .input-search {
                display: block;
                width: 100%;
                height: 55px;
                border: none;
                border-radius: 30px;
                padding-left: 20px;
                color: $black-color;
                font-size: $font-size;
            }
            button {
                position: absolute;
                right: 5px;
                top: 5px;
                width: 45px;
                color: $white-color;
                height: 45px;
                border-radius: 50%;
                background-color: $main-color;
                transition: $transition;
                border: none;
                font-size: 20px;
                line-height: 45px;

                &:hover {
                    background-color: $main-color2;
                    color: $white-color;
                }
            }
        }
    }
    &.search-overlay-active {
        &.search-overlay {
            opacity: 1;
            visibility: visible;

            .search-overlay-layer {
                transform: translateX(0);
            }
            .search-overlay-close {
                opacity: 1;
                visibility: visible;
            }
            .search-overlay-form {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    position: relative;
    z-index: 1;
    padding: {
        top: 300px;
        bottom: 205px;
    }
    background: {
        color: $black-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    &.banner-bg1 {
        background-image: url(assets/img/main-banner1.jpg);
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $black-color;
        opacity: 0.3;
    }
}
.main-banner-content {
    // max-width: 1050px;
    max-width: 83%;

    text-align: center;
    margin: {
        left: auto;
        right: auto;
    }
    h1 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 70px;
            weight: 700;
        }
    }
}
.main-search-wrap {
    background-color: $white-color;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 15px 20px;
    margin-top: 40px;

    form {
        position: relative;
        padding-right: 165px;

        .form-group {
            margin-bottom: 0;
            position: relative;

            label {
                z-index: 1;
                display: block;
                margin-bottom: 0;
                position: absolute;
                left: 0;
                color: $main-color;
                font-size: 22px;
                top: 6px;

                a {
                    display: block;
                    color: $main-color;
                }
            }
            input {
                display: block;
                padding: 0 0 0 28px;
                width: 100%;
                border: none;
                background-color: yellow;
                border-right: 1px solid #eeeeee;
                height: 45px;
                border-radius: 0;
                color: $black-color;
                font: {
                    size: 14.5px;
                }
                &::placeholder {
                    transition: $transition;
                    color: #7d7d7d;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            .nice-select {
                background-color: $white-color;
                width: 100%;
                border-radius: 0;
                border: none;
                border-right: 1px solid #eeeeee;
                display: block;
                height: 45px;
                line-height: 45px;
                color: #7d7d7d;
                padding: {
                    left: 29px;
                    right: 20px;
                    top: 0;
                    bottom: 0;
                }
                font: {
                    size: 14.5px;
                }
                &::after {
                    border-color: $main-color;
                    height: 8px;
                    width: 8px;
                    right: 15px;
                }
                .list {
                    background-color: $white-color;
                    border-radius: 0;
                    box-shadow: unset;
                    width: 100%;
                    -ms-overflow-style: none;
                    max-width: 100%;
                    height: 215px;
                    overflow-y: scroll;
                    margin: {
                        top: 0;
                        bottom: 0;
                    }
                    .option {
                        line-height: 40px;
                        min-height: 40px;
                        color: #7d7d7d;
                        padding: {
                            left: 15px;
                            top: 3px;
                            right: 15px;
                        }
                        &.selected {
                            background-color: $main-color !important;
                            color: $white-color;
                            font-weight: normal;
                        }
                    }
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
        .main-search-btn {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            max-width: 140px;

            button {
                display: block;
                background-color: $main-color;
                color: $white-color;
                border: none;
                padding: 13px 55px 13px 25px;
                text-align: start;
                border-radius: 30px;
                transition: $transition;
                position: relative;
                text-transform: uppercase;
                font: {
                    size: 14.5px;
                    family: $font-family2;
                    weight: 600;
                }
                i {
                    position: absolute;
                    right: 25px;
                    top: 50%;
                    transform: translateY(-50%) rotate(0);
                    font-size: 20px;
                    transition: $transition;
                }
                &:hover {
                    background-color: $main-color2;
                    color: $white-color;

                    i {
                        transform: translateY(-50%) rotate(80deg);
                    }
                }
            }
        }
    }
}
.main-categories-box {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    overflow: hidden;
    bottom: -70px;
    max-width: 620px;
    margin: {
        left: auto;
        right: auto;
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
            left: -5px;
            right: -5px;
            bottom: 0;
        }
        li {
            display: block;
            flex: 0 0 20%;
            max-width: 20%;
            padding: {
                left: 5px;
                right: 5px;
            }
            a {
                display: block;
                text-transform: uppercase;
                font: {
                    size: $font-size;
                    weight: 700;
                    family: $font-family2;
                }
                .icon {
                    display: block;
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    line-height: 70px;
                    font-size: 30px;
                    color: $main-color;
                    transition: $transition;
                    background-color: $white-color;
                    border: 5px dotted rgba(2, 128, 194, 0.35);
                    margin: {
                        left: auto;
                        right: auto;
                        bottom: 15px;
                    }
                }
                &:hover {
                    .icon {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}
.main-banner-wrapper {
    background-color: $white-color;
    position: relative;
    z-index: 1;
    padding: {
        top: 200px;
        bottom: 400px;
    }
    background: {
        image: url(assets/img/banner-bg1.jpg);
        position: bottom center;
        size: cover;
        repeat: no-repeat;
    }
}
.banner-wrapper-content {
    max-width: 650px;

    h1 {
        margin-bottom: 20px;
        font: {
            size: 50px;
            weight: 700;
        }
    }
    ngx-typed-js {
        display: inline-block;
    }
    p {
        line-height: 1.8;
        max-width: 600px;
        color: #635d5d;
        margin: {
            bottom: 0;
        }
    }
    form {
        position: relative;
        margin-top: 30px;
        max-width: 550px;

        .input-search {
            display: block;
            width: 100%;
            background-color: black;
            border: none;
            height: 60px;
            padding-left: 20px;
            border-radius: 70px;
            outline: 0;
            color: $black-color;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;

            &::placeholder {
                transition: $transition;
                color: #635d5d;
            }
            &:focus {
                &::placeholder {
                    color: white;
                }
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 50px;
            padding: 0 30px;
            border-radius: 70px;
            transition: $transition;
            text-transform: uppercase;
            font: {
                size: $font-size;
                weight: 600;
            }
            &:hover,
            &:focus {
                background-color: $main-color2;
                color: $white-color;
            }
        }
    }
}
.banner-wrapper {
    background-color: $black-color;
    position: relative;
    z-index: 1;
    background: linear-gradient(
            125deg,
            #00ff57 0%,
            #010033 40%,
            #460043 70%,
            #f0ffc5 100%
        ),
        linear-gradient(55deg, #0014c9 0%, #410060 100%),
        linear-gradient(300deg, #ffc700 0%, #001aff 100%),
        radial-gradient(
            135% 215% at 115% 40%,
            #393939 0%,
            #393939 40%,
            #849561 calc(40% + 1px),
            #849561 60%,
            #eed690 calc(60% + 1px),
            #eed690 80%,
            #ecefd8 calc(80% + 1px),
            #ecefd8 100%
        ),
        linear-gradient(
            125deg,
            #282d4f 0%,
            #282d4f 40%,
            #23103a calc(40% + 1px),
            #23103a 70%,
            #a0204c calc(70% + 1px),
            #a0204c 88%,
            #ff6c00 calc(88% + 1px),
            #ff6c00 100%
        );
    background-blend-mode: overlay, screen, overlay, overlay, normal;
    padding: {
        top: 250px;
        bottom: 170px;
    }
}
.wrapper-content {
    padding-right: 15px;

    h1 {
        color: $white-color;
        margin-bottom: 20px;
        font: {
            size: 60px;
            weight: 700;
        }
    }
    p {
        color: $white-color;
    }
    form {
        position: relative;
        margin: {
            top: 30px;
            right: 50px;
        }
        .input-search {
            display: block;
            width: 100%;
            background-color: purple;
            border: none;
            height: 60px;
            padding-left: 20px;
            border-radius: 70px;
            outline: 0;
            color: $black-color;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;

            &::placeholder {
                transition: $transition;
                color: $optional-color;
            }
            &:focus {
                &::placeholder {
                    color: white;
                }
            }
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background-color: $main-color;
            color: $white-color;
            border: none;
            height: 50px;
            padding: 0 30px;
            border-radius: 70px;
            transition: $transition;
            text-transform: uppercase;
            font: {
                size: $font-size;
                weight: 600;
            }
            &:hover,
            &:focus {
                background-color: $main-color2;
                color: $white-color;
            }
        }
    }
}
.banner-listing-category {
    text-align: center;
    padding-left: 15px;

    ul {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
            left: -8px;
            right: -8px;
            bottom: -16px;
        }
        li {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            padding: {
                left: 8px;
                right: 8px;
                bottom: 16px;
            }
            a {
                display: block;
                background-color: $white-color;
                border-radius: 5px;
                position: relative;
                box-shadow: 0 1px 6px 0 rgba(50, 50, 50, 0.4);
                padding: 15px;
                color: $black-color;
                font: {
                    size: 16px;
                    weight: 600;
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 3px;
                    background-color: $main-color;
                    transition: $transition;
                    opacity: 0;
                    visibility: hidden;
                }
                i {
                    display: block;
                    font-size: 45px;
                    margin-bottom: 8px;
                    color: $main-color;
                }
                &:hover,
                &:focus {
                    transform: scale(1.1);
                    border-radius: 0;

                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            &:nth-child(2),
            &:nth-child(11) {
                a {
                    i {
                        color: $optional-color;
                    }
                    &::before {
                        background-color: $optional-color;
                    }
                }
            }
            &:nth-child(3),
            &:nth-child(12) {
                a {
                    i {
                        color: #0093c9;
                    }
                    &::before {
                        background-color: #0093c9;
                    }
                }
            }
            &:nth-child(4),
            &:nth-child(13) {
                a {
                    i {
                        color: #2d3942;
                    }
                    &::before {
                        background-color: #2d3942;
                    }
                }
            }
            &:nth-child(5),
            &:nth-child(14) {
                a {
                    i {
                        color: #bfd131;
                    }
                    &::before {
                        background-color: #bfd131;
                    }
                }
            }
            &:nth-child(6),
            &:nth-child(15) {
                a {
                    i {
                        color: #f49b29;
                    }
                    &::before {
                        background-color: #f49b29;
                    }
                }
            }
            &:nth-child(7),
            &:nth-child(1165) {
                a {
                    i {
                        color: #833a8d;
                    }
                    &::before {
                        background-color: #833a8d;
                    }
                }
            }
            &:nth-child(8),
            &:nth-child(17) {
                a {
                    i {
                        color: #c81a7f;
                    }
                    &::before {
                        background-color: #c81a7f;
                    }
                }
            }
            &:nth-child(9),
            &:nth-child(18) {
                a {
                    i {
                        color: #1cb5a3;
                    }
                    &::before {
                        background-color: #1cb5a3;
                    }
                }
            }
        }
    }
}
.hero-banner {
    position: relative;
    z-index: 1;
    height: 100vh;
    background: {
        color: $black-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    &.banner-bg2 {
        background-image: url(assets/img/main-banner2.jpg);
    }
    &.banner-bg3 {
        background-image: url(assets/img/main-banner3.jpg);
    }
    &.banner-bg4 {
        background-image: url(assets/img/main-banner4.jpg);
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $black-color;
        opacity: 0.5;
    }
}
.home-area {
    .main-banner-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 52%;
        transform: translateY(-52%);
        z-index: 2;

        h1 {
            span {
                display: block;
            }
        }
    }
}
.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*="owl-"] {
                color: $white-color;
                font-size: 45px;
                margin: 0;
                padding: 0;
                background: transparent;
                display: inline-block;
                border-radius: 0;
                transition: $transition;
                position: absolute;
                left: 0;
                top: 55%;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-55%);

                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover {
                    color: $main-color;
                    transform: translateX(-10px) translateY(-55%);

                    &.owl-next {
                        transform: translateX(10px) translateY(-55%);
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*="owl-"] {
                    left: 20px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: 20px;
                    }
                }
            }
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;
}
.about-image {
    position: relative;
    padding: {
        right: 100px;
        bottom: 50px;
    }
    img {
        border-radius: 5px;

        &:nth-child(2) {
            position: absolute;
            right: 10px;
            bottom: 0;
            z-index: 1;
        }
    }
}
.about-content {
    padding-left: 15px;

    .sub-title {
        display: block;
        color: $main-color;
        text-transform: uppercase;
        margin-bottom: 8px;
        font: {
            style: italic;
            weight: 600;
            size: 14px;
        }
    }
    h2 {
        margin-bottom: 15px;
        line-height: 1.3;
        font: {
            size: 40px;
            weight: 700;
        }
    }
    h6 {
        margin-bottom: 14px;
        line-height: 1.6;
        font-size: 16px;
    }
    .features-text {
        margin-top: 25px;

        h5 {
            margin-bottom: 22px;
            position: relative;
            font: {
                size: 17px;
                weight: 600;
            }
            padding: {
                left: 47px;
                top: 8px;
            }
        }
        i {
            position: absolute;
            left: 0;
            top: 0;
            width: 35px;
            height: 35px;
            background-color: $main-color;
            text-align: center;
            line-height: 35px;
            color: $white-color;
            font-size: 20px;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
            border-radius: 50%;
        }
    }
}
.about-inner-area {
    margin-top: 80px;
}
.about-text {
    h3 {
        position: relative;
        margin-bottom: 20px;
        padding-bottom: 8px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 23px;
            weight: 700;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 50px;
            background-color: $main-color;
            height: 1px;
        }
    }
    .features-list {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 20px;
            bottom: 0;
        }
        li {
            position: relative;
            margin-bottom: 13px;
            color: $black-color;
            padding: {
                left: 30px;
            }
            color: $optional-color;
            i {
                font-size: 16px;
                color: $black-color;
                background-color: #eaeaf2;
                width: 20px;
                height: 20px;
                line-height: 20px;
                border-radius: 50%;
                transition: $transition;
                text-align: center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    background-color: #f4f4f4;
}
.partner-item {
    text-align: center;

    a {
        display: block;
        background-color: $white-color;
        border-right: 1px solid #eeeeee;
        padding: 20px;

        img {
            width: auto !important;
            display: inline-block !important;
            opacity: 0.5;
            transition: $transition;
        }
        &:hover {
            img {
                opacity: 1;
            }
        }
    }
}

/*================================================
Listing Area CSS
=================================================*/
.listing-area {
    &.extra-pt {
        padding-top: 160px;
    }
    .container-fluid {
        max-width: 1920px;
    }
}
.single-listing-box {
    border-radius: 5px;
    position: relative;
    margin-bottom: 30px;
    padding-right: 8px;

    .listing-image {
        display: block;
        border-radius: 5px;
        overflow: hidden;
        position: relative;

        img {
            width: 100% !important;
            display: inline-block !important;
            border-radius: 5px;
            transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 5%;
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.99) 100%
            );
            z-index: 2;
        }
    }
    .listing-badge {
        width: 100px;
        height: 38px;
        line-height: 39px;
        padding-left: 14px;
        position: absolute;
        color: $white-color;
        top: 33px;
        right: 0;
        text-transform: uppercase;
        background-color: $main-color;
        font: {
            size: 12.5px;
            weight: 500;
            family: $font-family2;
        }
        &::before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -8.5px;
            right: 0;
            border-bottom: 9px solid $main-color;
            border-right: 9px solid transparent;
        }
        &.closed {
            color: $white-color;
            background-color: red;
            width: 115px;

            &::before {
                border-bottom-color: red;
            }
        }
    }
    .listing-content {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        z-index: 3;
        padding: 30px;

        .content {
            position: relative;

            .author {
                margin-bottom: 13px;

                img {
                    display: inline-block !important;
                    border-radius: 50%;
                    width: 40px !important;
                    border: 2px solid $white-color;
                }
                span {
                    color: $white-color;
                    position: relative;
                    margin-left: 8px;
                    top: 3px;
                    display: inline-block;
                    font: {
                        size: $font-size;
                        weight: 600;
                    }
                }
            }
            h3 {
                margin-bottom: 0;
                color: $white-color;
                font: {
                    size: 24px;
                    weight: 700;
                }
                a {
                    display: inline-block;
                    color: $white-color;
                }
            }
            .location {
                display: block;
                color: $white-color;
                margin-top: 10px;
                position: relative;
                opacity: 0.95;
                padding-left: 19px;
                font: {
                    size: 13.5px;
                    weight: 600;
                }
                i {
                    position: absolute;
                    left: 0;
                    top: 3px;
                }
            }
            .rating {
                position: absolute;
                right: 0;
                bottom: 0;
                text-align: end;

                i {
                    color: #ffc107;
                }
                span {
                    display: block;
                    color: $white-color;
                    opacity: 0.9;
                    margin-top: 2px;
                    font: {
                        size: 13.5px;
                        weight: 600;
                    }
                }
            }
        }
        .footer-content {
            border-top: 1px solid rgba(255, 255, 255, 0.4);
            padding-top: 20px;
            margin-top: 20px;

            .listing-option-list {
                a {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 31px;
                    text-align: center;
                    margin-right: 5px;
                    background-color: $main-color;
                    color: $white-color;
                    border-radius: 2px;

                    &:last-child {
                        margin-right: 0;
                    }
                    &:hover {
                        background-color: $white-color;
                        color: $main-color;
                    }
                }
            }
            .price-level {
                span {
                    display: inline-block;
                    color: $white-color;
                    cursor: pointer;
                }
            }
            .listing-category {
                a {
                    display: inline-block;
                    color: $white-color;
                    position: relative;
                    padding-left: 26px;
                    font: {
                        size: $font-size;
                        weight: 600;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    &:hover {
        .listing-image {
            img {
                transform: scale(1.15);
            }
        }
    }
}
.listing-slides {
    &.owl-theme {
        .owl-dots {
            margin-top: 10px !important;

            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 4px;
                    background: transparent;
                    position: relative;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $optional-color;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 50%;
                        background-color: $optional-color;
                        margin: 3px;
                    }
                }
                &:hover,
                &.active {
                    span {
                        border-color: $main-color;

                        &::before {
                            background-color: $main-color;
                        }
                    }
                }
            }
        }
    }
}
.single-listing-item {
    background-color: $white-color;
    box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    border-radius: 5px 5px 0 0;
    transition: $transition;
    margin-bottom: 30px;
    position: relative;

    .listing-image {
        border-radius: 5px 5px 0 0;
        position: relative;

        a {
            img {
                border-radius: 5px 5px 0 0;
            }
        }
        .listing-tag {
            position: absolute;
            right: 20px;
            bottom: 0;
            background-color: $white-color;
            border-radius: 5px 5px 0 0;
            transition: $transition;
            padding: {
                left: 15px;
                right: 15px;
                top: 5px;
                bottom: 5px;
            }
            font: {
                size: 14px;
                weight: 600;
            }
            a {
                display: inline-block;
                color: $main-color;
            }
        }
        .listing-rating {
            position: absolute;
            left: 20px;
            color: $white-color;
            bottom: 5px;

            .review-stars-rated {
                color: #f2b827;
                display: inline-block;
                margin-right: 5px;

                i {
                    color: #f2b827;
                }
            }
            .rating-total {
                display: inline-block;
                position: relative;
                color: $white-color;
                top: -1px;
                margin-left: 2px;
                font: {
                    size: 13px;
                    weight: 600;
                }
            }
        }
    }
    .listing-content {
        padding: 25px 20px;

        .listing-author {
            margin-bottom: 16px;

            img {
                width: 37px;
                height: 37px;
            }
            span {
                margin-left: 3px;
                display: inline-block;
                font: {
                    size: $font-size;
                    weight: 600;
                }
            }
        }
        h3 {
            margin-bottom: 0;
            line-height: 1.3;
            font: {
                size: 22px;
                weight: 700;
            }
        }
        .location {
            display: block;
            color: $optional-color;
            margin-top: 12px;
            position: relative;
            padding-left: 18px;
            font: {
                size: 13.5px;
                weight: 600;
            }
            i {
                position: absolute;
                left: 0;
                color: $main-color;
                top: 3.7px;
            }
        }
        .phone-number {
            display: block;
            color: $optional-color;
            margin-top: 12px;
            position: relative;
            padding-left: 18px;
            font: {
                size: 13.5px;
                weight: 600;
            }
            i {
                position: absolute;
                left: 0;
                color: $main-color;
                top: 3.7px;
            }
            a {
                display: inline-block;
                color: $optional-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
        .email {
            display: block;
            color: $optional-color;
            margin-top: 12px;
            position: relative;
            padding-left: 18px;
            font: {
                size: 13.5px;
                weight: 600;
            }
            i {
                position: absolute;
                left: 0;
                color: $main-color;
                top: 3.7px;
            }
            a {
                display: inline-block;
                color: $optional-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .listing-box-footer {
        border-top: 1px solid #e2f4ff;
        padding: 20px;

        .listing-option-list {
            a {
                display: inline-block;
                width: 32px;
                height: 32px;
                line-height: 33px;
                text-align: center;
                margin-right: 5px;
                box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                background-color: $white-color;
                color: $black-color;
                border-radius: 2px;

                &:last-child {
                    margin-right: 0;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
        .price {
            span {
                display: inline-block;
                cursor: pointer;
                color: $main-color;
                font: {
                    weight: 600;
                    size: $font-size;
                }
            }
        }
    }
    .listing-badge {
        position: absolute;
        right: 20px;
        top: 20px;
        background-color: #5ecfb1;
        color: $white-color;
        text-align: center;
        z-index: 2;
        padding: 3px 12px;
        text-transform: uppercase;
        font: {
            weight: 600;
            size: 13.5px;
        }
        &.closed {
            background-color: red;
            color: $white-color;
        }
    }
    .listing-discount {
        position: absolute;
        top: -6.1px;
        left: 20px;
        display: inline-block;

        span {
            position: relative;
            display: block;
            text-align: center;
            line-height: 1;
            padding: 12px 8px 10px;
            border-top-right-radius: 8px;
            width: 90px;
            background-color: $main-color;
            color: $white-color;
            text-transform: uppercase;
            font: {
                weight: 600;
                size: 14px;
            }
            &::before {
                height: 6px;
                width: 6px;
                left: -6px;
                position: absolute;
                content: "";
                top: 0;
                background-color: $main-color;
            }
            &::after {
                position: absolute;
                content: "";
                height: 6px;
                width: 8px;
                left: -8px;
                top: 0;
                border-radius: 8px 8px 0 0;
                background-color: $main-color;
            }
        }
    }
    &:hover,
    &:focus {
        transform: translateY(-10px);

        .listing-image {
            .listing-tag {
                background-color: $main-color;

                a {
                    color: $white-color;
                }
            }
        }
    }
}
.listing-items {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));

    > * {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
    }
}
.listing-topbar {
    margin-bottom: 40px;

    .topbar-title {
        h2 {
            margin-bottom: 0;
            font: {
                size: 25px;
                weight: 600;
            }
        }
    }
    .topbar-ordering-and-categories {
        text-align: end;

        .topbar-ordering {
            .nice-select {
                background-color: transparent;
                border: none;
                height: 45px;
                width: 100%;
                box-shadow: 0 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);
                line-height: 45px;
                color: $optional-color;
                font-size: $font-size;
                border-radius: 0;
                text-transform: capitalize;
                padding: {
                    left: 15px;
                    right: 15px;
                }
                font: {
                    size: 14.5px;
                    weight: 500;
                }
                &::after {
                    right: 15px;
                    width: 7px;
                    height: 7px;
                    border-color: $optional-color;
                    border-width: 1px;
                }
                .list {
                    background-color: $white-color;
                    border-radius: 0;
                    box-shadow: unset;
                    width: 100%;
                    -ms-overflow-style: none;
                    max-width: 100%;
                    height: 240px;
                    overflow-y: scroll;
                    margin: {
                        top: 0;
                        bottom: 0;
                    }
                    .option {
                        line-height: 40px;
                        min-height: 40px;
                        color: #7d7d7d;
                        padding: {
                            left: 15px;
                            right: 15px;
                        }
                        &.selected {
                            background-color: $main-color !important;
                            color: $white-color;
                            font-weight: normal;
                        }
                    }
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
        .topbar-categories {
            .nice-select {
                background-color: transparent;
                border: none;
                height: 45px;
                box-shadow: 0 0.2rem 1rem 0rem rgba(0, 0, 0, 0.1);
                line-height: 45px;
                color: $optional-color;
                font-size: $font-size;
                width: 100%;
                border-radius: 0;
                text-transform: capitalize;
                padding: {
                    left: 15px;
                    right: 15px;
                }
                font: {
                    size: 14.5px;
                    weight: 500;
                }
                &::after {
                    right: 15px;
                    width: 7px;
                    height: 7px;
                    border-color: $optional-color;
                    border-width: 1px;
                }
                .list {
                    background-color: $white-color;
                    border-radius: 0;
                    box-shadow: unset;
                    width: 100%;
                    -ms-overflow-style: none;
                    max-width: 100%;
                    height: 240px;
                    overflow-y: scroll;
                    margin: {
                        top: 0;
                        bottom: 0;
                    }
                    .option {
                        line-height: 40px;
                        min-height: 40px;
                        color: #7d7d7d;
                        padding: {
                            left: 15px;
                            right: 15px;
                        }
                        &.selected {
                            background-color: $main-color !important;
                            color: $white-color;
                            font-weight: normal;
                        }
                    }
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }
}
.listing-list-tab {
    .tabs {
        text-align: center;
        list-style-type: none;
        background-color: $white-color;
        box-shadow: 0 2px 16px 0px rgba(146, 184, 255, 0.15);
        border-radius: 5px;
        max-width: 410px;
        margin: {
            left: auto;
            right: auto;
            bottom: 40px;
        }
        padding: {
            left: 20px;
            right: 20px;
        }
        li {
            display: inline-block;
            margin: {
                left: 15px;
                right: 15px;
            }
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
            a {
                display: inline-block;
                position: relative;
                text-transform: uppercase;
                font: {
                    size: $font-size;
                    weight: 700;
                }
                padding: {
                    top: 15px;
                    bottom: 15px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 3px;
                    transition: $transition;
                    background: #eeeeee;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 3px;
                    transition: $transition;
                    background-color: $main-color;
                    transform: scaleX(0);
                }
                &:hover,
                &:focus {
                    color: $main-color;

                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
            &.current {
                a {
                    color: $main-color;

                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}
.tab {
    .tabs-item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}
.listing-filter-options {
    margin-bottom: 25px;

    .listing-ordering-list {
        .nice-select {
            background-color: transparent;
            border: none;
            height: 35px;
            line-height: 35px;
            color: $optional-color;
            font-size: $font-size;
            width: 100%;
            padding: {
                left: 0;
                right: 0;
            }
            &::after {
                right: 2px;
                width: 7px;
                height: 7px;
                border-color: #727695;
                border-width: 1px;
            }
            .current {
                color: $optional-color;
                font-family: $font-family2;
            }
            .list {
                background-color: $white-color;
                border-radius: 0;
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
                width: 100%;
                margin: {
                    top: 0;
                    bottom: 0;
                }
                padding: {
                    top: 5px;
                    bottom: 5px;
                }
                .option {
                    transition: $transition;
                    line-height: 35px;
                    min-height: 35px;
                    padding: {
                        left: 15px;
                        right: 15px;
                    }
                    font: {
                        size: 14.5px;
                    }
                    &:hover,
                    &.focus,
                    &.selected.focus {
                        background-color: transparent;
                        color: $main-color;
                    }
                    &.selected {
                        font-weight: 700;
                    }
                }
            }
        }
    }
    p {
        line-height: initial;
        margin-bottom: 0;
        font-family: $font-family2;
    }
}

/*================================================
Listing Details Area CSS
=================================================*/
.listing-details-header {
    margin-bottom: 30px;

    .listing-title {
        h2 {
            margin-bottom: 10px;
            font: {
                size: 25px;
                weight: 700;
            }
        }
    }
    .listing-meta {
        margin-top: 25px;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-right: 20px;
                position: relative;
                display: inline-block;
                border-right: 1px solid #eeeeee;
                font: {
                    weight: 500;
                    size: 16px;
                }
                padding: {
                    right: 20px;
                    left: 37px;
                }
                i {
                    font-size: 25px;
                    color: #cfcfcf;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                span {
                    display: block;
                    color: $main-color;
                    text-transform: uppercase;
                    margin-bottom: 2px;
                    font: {
                        size: 13.5px;
                        weight: 600;
                    }
                }
                a {
                    display: inline-block;
                    color: $black-color;
                    font-weight: 700;

                    &:hover,
                    &:focus {
                        color: $main-color;
                    }
                }
                &:last-child {
                    padding-right: 0;
                    margin-right: 0;
                    border-right: none;
                }
            }
        }
    }
    .listing-price {
        text-align: end;

        .price {
            display: inline-block;
            margin-right: 15px;
            position: relative;
            top: 6px;
            font: {
                size: 30px;
                weight: 700;
            }
        }
        .default-btn {
            border-radius: 5px;
        }
        .listing-review {
            display: block;
            margin-bottom: 10px;

            .review-stars {
                display: inline-block;

                i {
                    color: #f2b827;
                    font-size: 17px;
                    display: inline-block;
                }
            }
            .reviews-total {
                position: relative;
                color: $optional-color;
                position: relative;
                top: -2px;
                font-weight: 500;
                margin-left: 3px;
            }
        }
    }
}
.listing-details-image {
    img {
        width: 100%;
    }
}
.listing-details-image-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            transition: $transition;
            opacity: 0;
            visibility: hidden;

            [class*="owl-"] {
                color: #87a1c1;
                font-size: 18px;
                margin: 0;
                padding: 0;
                background: $white-color;
                width: 45px;
                height: 45px;
                line-height: 42px;
                position: absolute;
                left: 20px;
                transition: $transition;
                top: 50%;
                padding-right: 2px !important;
                transform: translateY(-50%);
                border: {
                    radius: 50%;
                    style: solid;
                    color: $white-color;
                    width: 1.5px;
                }
                &.owl-next {
                    left: auto;
                    right: 20px;
                    padding: {
                        right: 0 !important;
                        left: 2px !important;
                    }
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.listing-details-desc {
    margin-top: 35px;

    h3 {
        margin-bottom: 15px;
        font: {
            size: 25px;
            weight: 700;
        }
        &:not(first-child) {
            margin-top: 30px;
        }
    }
    .amenities-list {
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            padding-left: 0;
            margin: {
                left: -10px;
                right: -10px;
                bottom: -12px;
            }
            li {
                flex: 0 0 50%;
                max-width: 50%;
                padding: {
                    left: 10px;
                    right: 10px;
                    bottom: 12px;
                }
                span {
                    display: block;
                    color: $black-color;
                    position: relative;
                    padding-left: 27px;
                    line-height: 1.7;
                    font: {
                        weight: 600;
                        size: 14.5px;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: $main-color;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    p {
        strong {
            color: $black-color;
        }
    }
    .description-features-list {
        padding-left: 15px;
        margin-bottom: 15px;
        list-style-type: none;

        li {
            color: $optional-color;
            margin-bottom: 10px;
            position: relative;
            padding-left: 17px;
            line-height: 1.8;

            &::before {
                position: absolute;
                left: 0;
                content: "";
                top: 10px;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: $main-color;
            }
            strong {
                color: $black-color;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .listing-author {
        margin-top: 20px;
        border-radius: 5px;
        background: #f8f8f8;

        .author-profile-header {
            height: 115px;
            border-radius: 5px 5px 0 0;
            background: {
                color: $main-color;
                image: url(assets/img/bg.jpg);
                position: center center;
                size: cover;
                repeat: no-repeat;
            }
        }
        .author-profile {
            padding: 0 25px 25px;

            .author-profile-title {
                position: relative;
                z-index: 1;
                margin: {
                    bottom: 15px;
                    top: -45px;
                }
                img {
                    display: inline-block;
                    border: 3px solid $white-color;
                    width: 100px;
                    height: 100px;
                }
                .author-profile-title-details {
                    margin-top: 15px;

                    .author-profile-details {
                        h4 {
                            margin-bottom: 5px;
                            font: {
                                size: 20px;
                                weight: 700;
                            }
                        }
                        span {
                            color: $optional-color;
                        }
                    }
                }
                .author-profile-raque-profile {
                    a {
                        display: inline-block;
                        text-transform: capitalize;
                        font: {
                            weight: 600;
                            size: 14px;
                        }
                    }
                }
            }
            p {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    #map {
        height: auto;

        iframe {
            width: 100%;
            height: 400px;
            border: none;
        }
    }
}
.related-listing {
    margin-top: 40px;

    h3 {
        margin-bottom: 30px;
        font: {
            size: 25px;
            weight: 700;
        }
    }
}
.listing-sidebar-widget {
    padding-left: 15px;

    h3 {
        margin-bottom: 25px;
        position: relative;
        padding-bottom: 8px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 22px;
            weight: 700;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 50px;
            height: 1px;
            bottom: -1px;
            background-color: $main-color;
        }
    }
    .listing-opening-hours {
        margin-bottom: 35px;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                border-bottom: 1px dashed #eeeeee;
                color: $optional-color;
                overflow: hidden;
                text-align: end;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                font: {
                    size: 14.5px;
                    weight: 600;
                }
                span {
                    display: block;
                    width: 170px;
                    text-align: start;
                    float: left;

                    i {
                        color: $main-color;
                        position: relative;
                        top: 4px;
                        transition: $transition;
                        font-size: 20px;
                        margin-right: 2px;
                    }
                }
                &:hover {
                    span {
                        i {
                            transform: rotateY(-180deg);
                        }
                    }
                }
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }
    .listing-contact-info {
        margin-bottom: 35px;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                border-bottom: 1px dashed #eeeeee;
                color: $optional-color;
                font-size: 14.5px;
                padding: {
                    bottom: 10px;
                    top: 10px;
                }
                &:first-child {
                    border-top: 1px dashed #eeeeee;
                }
                span {
                    display: inline-block;
                    color: $black-color;
                    margin-right: 2px;
                    font: {
                        weight: 600;
                    }
                }
                a {
                    display: inline-block;
                    color: $optional-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    .listing-book-table {
        margin-bottom: 35px;

        form {
            .form-control {
                font-size: 14px;
                height: 45px;
            }
            .default-btn {
                padding: 10.5px 28px 11px;
                border-radius: 0;
                font-size: 14px;
            }
            label {
                display: block;
                margin-bottom: 7px;
                font: {
                    size: 14.5px;
                    weight: 600;
                }
            }
            .input-counter {
                max-width: 120px;
                min-width: 120px;
                text-align: center;
                position: relative;

                span {
                    position: absolute;
                    top: 0;
                    background-color: transparent;
                    cursor: pointer;
                    color: #d0d0d0;
                    width: 40px;
                    height: 100%;
                    line-height: 49px;
                    transition: $transition;
                    font-size: 22px;

                    &.minus-btn {
                        left: 0;
                    }
                    &.plus-btn {
                        right: 0;
                    }
                    &:hover {
                        color: $main-color;
                    }
                }
                input {
                    height: 45px;
                    color: $black-color;
                    outline: 0;
                    display: block;
                    border: none;
                    background-color: #ffffff;
                    text-align: center;
                    width: 100%;
                    font: {
                        size: 17px;
                        weight: 600;
                    }
                    &::placeholder {
                        color: $black-color;
                    }
                }
            }
        }
    }
    .listing-instagram {
        margin-bottom: 35px;

        ul {
            padding-left: 0;
            list-style-type: none;
            flex-wrap: wrap;
            display: flex;
            margin: {
                bottom: -10px;
                left: -5px;
                right: -5px;
            }
            li {
                flex: 0 0 33.3333333333%;
                max-width: 33.3333333333%;
                padding: {
                    bottom: 10px;
                    right: 5px;
                    left: 5px;
                }
                a {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $main-color;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: 0.7;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .listing-contact {
        position: relative;
        z-index: 1;
        border-radius: 5px;
        height: 400px;
        text-align: center;
        background: {
            image: url(assets/img/main-banner4.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
        &::before {
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px;
            background-color: $main-color;
            opacity: 0.5;
            z-index: -1;
        }
        .text {
            position: absolute;
            left: 0;
            bottom: 25px;
            right: 0;

            .icon {
                width: 65px;
                height: 65px;
                border: 2px dashed $main-color;
                position: relative;
                border-radius: 50%;
                font-size: 35px;
                transition: $transition;
                background-color: $white-color;
                color: $main-color;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 17px;
                }
                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            span {
                display: block;
                color: $white-color;
                margin-bottom: 8px;
                font: {
                    size: 16px;
                    weight: 600;
                }
            }
            a {
                display: inline-block;
                color: $white-color;
                font: {
                    weight: 700;
                    size: 25px;
                }
            }
        }
    }
}
.listing-review-comments {
    margin-top: 40px;

    h3 {
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 10px;
        margin: {
            top: 0;
            bottom: 0;
        }
    }
    .user-review {
        border-bottom: 1px solid #f3f3f3;
        padding: 20px 0 20px 110px;
        position: relative;

        img {
            position: absolute;
            left: 0;
            top: 20px;
            width: 90px;
            height: 90px;
            border-radius: 5px;
        }
        .sub-comment {
            margin-bottom: 10px;
            font: {
                size: 17px;
                weight: 600;
            }
        }
        .review-rating {
            display: block;
            margin-bottom: 10px;

            .review-stars {
                display: inline-block;

                i {
                    color: #f2b827;
                    font-size: 18px;
                    display: inline-block;
                }
            }
            span {
                color: $black-color;
                position: relative;
                top: -2px;
                font-weight: 600;
                margin-left: 5px;
            }
        }
    }
}

/*================================================
Destinations Area CSS
=================================================*/
.destinations-items {
    margin: {
        left: -15px;
        right: -15px;
    }
}
.single-destinations-box {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 5px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 5px;
        right: 0;
        top: 20%;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.9) 100%
        );
        z-index: 2;
    }
    img {
        transition: $transition;
        border-radius: 5px;
    }
    .link-btn {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
    }
    .content {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 30px;
        width: 100%;
        transition: $transition;
        height: auto;
        z-index: 2;

        h3 {
            margin-bottom: 0;
            color: $white-color;
            font: {
                size: 24px;
                weight: 700;
            }
        }
        .rating {
            color: #ffc107;
            margin-top: 6px;

            i {
                margin-right: 2px;
                display: inline-block;
            }
        }
    }
    .listing-count {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
        background-color: $white-color;
        border-radius: 30px;
        color: $black-color;
        text-transform: uppercase;
        transition: $transition;
        padding: 5px 15px;
        font: {
            size: 14px;
            weight: 600;
        }
    }
    &:hover {
        .content {
            transform: translateY(-15px);
        }
        .listing-count {
            background-color: $main-color;
            color: $white-color;
        }
        img {
            transform: scale(1.15);
        }
    }
}
.destinations-btn-box {
    text-align: right;
    margin-top: 20px;
}

/*================================================
Video Area CSS
=================================================*/
.video-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/video-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    padding-top: 150px;
    padding-bottom: 150px;
}
.video-content {
    text-align: center;

    .sub-title {
        display: block;
        color: $white-color;
        text-transform: uppercase;
        margin-bottom: 13px;
        font: {
            style: italic;
            weight: 600;
            size: 14px;
        }
    }
    h2 {
        color: $white-color;
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
        }
        font: {
            size: 40px;
            weight: 700;
        }
    }
    .video-btn {
        display: inline-block;
        width: 80px;
        height: 80px;
        font-size: 50px;
        line-height: 85px;
        background-color: $main-color;
        border-radius: 50%;
        color: $white-color;
        margin-top: 50px;
        position: relative;
        z-index: 1;

        &::after,
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: $transition;
            border-radius: 50%;
            border: 1px solid $main-color;
        }
        &::before {
            animation: ripple 2s linear infinite;
        }
        &::after {
            animation: ripple 2s linear 1s infinite;
        }
        &:hover {
            color: $main-color;
            background-color: $white-color;

            &::before,
            &::after {
                border-color: $white-color;
            }
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/*================================================
Listing Categories Area CSS
=================================================*/
.single-categories-listing-item {
    position: relative;
    z-index: 1;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    padding: 40px 20px;
    margin-bottom: 30px;
    transition: $transition;
    background: {
        color: $main-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    .icon {
        width: 100px;
        height: 100px;
        position: relative;
        background: rgba(255, 255, 255, 0.2);
        margin-bottom: 30px;
        font-size: 60px;
        color: $white-color;
        transition: all 0.2s ease-in-out 0s;
        border: {
            width: 2px;
            style: dashed;
            color: rgba(255, 255, 255, 0.3);
            radius: 50%;
        }
        margin: {
            left: auto;
            right: auto;
        }
        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
        }
    }
    h3 {
        color: $white-color;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out 0s;
        font: {
            size: 24px;
            weight: 700;
        }
    }
    span {
        transition: all 0.4s ease-in-out 0s;
        text-transform: uppercase;
        display: block;
        color: $white-color;
        font: {
            weight: 500;
            size: 14px;
        }
    }
    .link-btn {
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        background-color: $black-color;
        opacity: 0.5;
        z-index: -1;
    }
    .learn-more-btn {
        display: inline-block;
        position: absolute;
        color: $white-color;
        z-index: 1;
        padding-right: 19px;
        transition: all 0.5s ease-in-out 0s;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        font: {
            size: $font-size;
            weight: 700;
        }
        i {
            font-size: 22px;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }
    &.bg1 {
        background-image: url(assets/img/categories/img1.jpg);
    }
    &.bg2 {
        background-image: url(assets/img/categories/img2.jpg);
    }
    &.bg3 {
        background-image: url(assets/img/categories/img3.jpg);
    }
    &.bg4 {
        background-image: url(assets/img/categories/img4.jpg);
    }
    &.bg5 {
        background-image: url(assets/img/categories/img5.jpg);
    }
    &.bg6 {
        background-image: url(assets/img/categories/img6.jpg);
    }
    &.bg7 {
        background-image: url(assets/img/categories/img7.jpg);
    }
    &.bg8 {
        background-image: url(assets/img/categories/img8.jpg);
    }
    &:hover,
    &:focus {
        transform: translateY(-10px);

        .icon {
            border-color: $white-color;
            transform: translateY(-18px);
        }
        h3 {
            transform: translateY(-18px);
        }
        span {
            transform: translateY(-18px);
        }
        .learn-more-btn {
            opacity: 1;
            visibility: visible;
            bottom: 18px;
        }
    }
}
.single-listing-category {
    text-align: center;
    margin-bottom: 30px;

    a {
        display: block;
        background-color: $white-color;
        border-radius: 5px;
        position: relative;
        box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
        padding: 25px;
        color: $black-color;
        z-index: 1;
        font: {
            size: 20px;
            weight: 600;
        }
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2.5px;
            background-color: $main-color;
            z-index: -1;
            transition: $transition;
        }
        i {
            display: block;
            font-size: 55px;
            margin-bottom: 12px;
            color: $main-color;
            transition: $transition;
        }
        &:hover,
        &:focus {
            border-radius: 0;
            color: $white-color;
            transform: translateY(-7px);

            i {
                color: $white-color;
            }
            &::before {
                height: 100%;
            }
        }
    }
}
.owl-item,
.col-lg-3 {
    &:nth-child(2),
    &:nth-child(11) {
        .single-listing-category {
            a {
                i {
                    color: $optional-color;
                }
                &::before {
                    background-color: $optional-color;
                }
                &:hover,
                &:focus {
                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
    &:nth-child(3),
    &:nth-child(12) {
        .single-listing-category {
            a {
                i {
                    color: #ff1949;
                }
                &::before {
                    background-color: #ff1949;
                }
                &:hover,
                &:focus {
                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
    &:nth-child(4),
    &:nth-child(13) {
        .single-listing-category {
            a {
                i {
                    color: #2d3942;
                }
                &::before {
                    background-color: #2d3942;
                }
                &:hover,
                &:focus {
                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
    &:nth-child(5),
    &:nth-child(14) {
        .single-listing-category {
            a {
                i {
                    color: #bfd131;
                }
                &::before {
                    background-color: #bfd131;
                }
                &:hover,
                &:focus {
                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
    &:nth-child(6),
    &:nth-child(15) {
        .single-listing-category {
            a {
                i {
                    color: #f49b29;
                }
                &::before {
                    background-color: #f49b29;
                }
                &:hover,
                &:focus {
                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
    &:nth-child(7),
    &:nth-child(1165) {
        .single-listing-category {
            a {
                i {
                    color: #833a8d;
                }
                &::before {
                    background-color: #833a8d;
                }
                &:hover,
                &:focus {
                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
    &:nth-child(8),
    &:nth-child(17) {
        .single-listing-category {
            a {
                i {
                    color: #c81a7f;
                }
                &::before {
                    background-color: #c81a7f;
                }
                &:hover,
                &:focus {
                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
    &:nth-child(9),
    &:nth-child(18) {
        .single-listing-category {
            a {
                i {
                    color: #1cb5a3;
                }
                &::before {
                    background-color: #1cb5a3;
                }
                &:hover,
                &:focus {
                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*================================================
Process Area CSS
=================================================*/
.process-area {
    position: relative;
    z-index: 1;
    background-color: #f4f5fe;
    background: {
        image: url(assets/img/white-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    .row {
        position: relative;

        .col-lg-12 {
            position: unset;
        }
    }
    .lines {
        .line {
            background: rgba(2, 128, 194, 0.05);

            &::after {
                background: linear-gradient(
                    to bottom,
                    rgba(2, 128, 194, 0) 0%,
                    $main-color 75%,
                    $main-color 100%
                );
            }
        }
    }
}
.single-process-box {
    text-align: center;
    position: relative;
    z-index: 1;

    .icon {
        border: 4px solid $white-color;
        width: 85px;
        height: 85px;
        text-align: center;
        color: $white-color;
        line-height: 79px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        font-size: 35px;
        background-color: $main-color;
        margin: {
            left: auto;
            right: auto;
            bottom: 25px;
        }
        i {
            transition: $transition;
        }
    }
    .content {
        position: relative;
        z-index: 1;

        h3 {
            margin-bottom: 10px;
            font: {
                size: 24px;
                weight: 700;
            }
        }
        p {
            margin: 0;
            color: #3e3c3c;
        }
        .number {
            color: $white-color;
            position: absolute;
            bottom: 0;
            left: 25px;
            z-index: -1;
            font: {
                family: $font-family2;
                size: 100px;
                weight: 500;
                style: italic;
            }
        }
    }
}
.process-arrow-icon {
    position: absolute;
    left: 155px;
    z-index: -1;
    top: -35px;
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    position: relative;
    z-index: 1;
    margin: {
        top: 30px;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background-color: #eeeeee;
        z-index: -1;
    }
    .page-numbers {
        color: $black-color;
        display: inline-block;
        line-height: 1;
        background-color: $white-color;
        font: {
            weight: 600;
            size: 20px;
        }
        margin: {
            left: -1px;
            right: -1px;
            top: -10px;
            bottom: -10px;
        }
        padding: {
            left: 8px;
            right: 8px;
        }
        &:hover,
        &:focus,
        &.current {
            color: $main-color;
        }
        &.prev {
            padding-right: 0;
        }
        &.next {
            padding-left: 0;
        }
        &.prev,
        &.next {
            font-size: 28px;
            position: relative;
            top: 5px;
            margin: {
                left: -3px;
                right: -3px;
            }
        }
    }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
    position: relative;
    z-index: 1;
}
.single-feedback-item {
    text-align: start;
    max-width: 720px;
    margin: {
        left: auto;
        right: auto;
    }
    p {
        color: $black-color;
        margin-bottom: 0;
        font: {
            weight: 600;
            style: italic;
            size: 22px;
        }
    }
    .info {
        text-align: end;
        margin-top: 50px;
        position: relative;
        z-index: 1;
        padding: {
            right: 118px;
            bottom: 22px;
        }
        img {
            width: 100px !important;
            height: 100px;
            display: inline-block !important;
            border: 3px solid $white-color;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        h3 {
            margin: {
                bottom: 4px;
                top: 20px;
            }
            font: {
                size: 22px;
                weight: 700;
            }
        }
        span {
            color: $main-color;
            display: block;
            font: {
                weight: 600;
                size: 14px;
            }
        }
        &::before {
            content: "";
            position: absolute;
            right: 320px;
            bottom: 45px;
            width: 110px;
            height: 75px;
            background: {
                position: center center;
                size: cover;
                repeat: no-repeat;
                image: url(assets/img/arrow2.png);
            }
        }
    }
}
.feedback-slides {
    &.owl-theme {
        margin-bottom: -40px;

        .single-feedback-item {
            margin-bottom: 40px;
        }
        .owl-nav {
            margin-top: 0;

            [class*="owl-"] {
                color: #87a1c1;
                font-size: 25px;
                margin: 0;
                padding: 0;
                background: transparent;
                width: 50px;
                height: 50px;
                line-height: 49px;
                position: absolute;
                left: 70px;
                transition: $transition;
                top: 50%;
                transform: translateY(-50%);
                border: {
                    radius: 50%;
                    style: solid;
                    color: #e3e4ef;
                    width: 2px;
                }
                &.owl-next {
                    left: auto;
                    right: 70px;
                }
                &:hover,
                &:focus {
                    background-color: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                    box-shadow: 0px 8px 16px 0px rgba(2, 128, 194, 0.2);
                }
                &.disabled {
                    color: #e3e4ef;

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: #e3e4ef;
                        border-color: #e3e4ef;
                        box-shadow: unset;
                    }
                }
            }
        }
    }
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
    img {
        width: 100%;
    }
    .slick-slider {
        position: relative;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .slick-dots {
        padding: 0;
        text-align: center;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
            left: -5px;
            right: -5px;
            top: 0;
            bottom: 0;
        }
        li {
            display: block;
            flex: 0 0 20%;
            max-width: 20%;
            position: relative;
            padding: {
                left: 5px;
                right: 5px;
                top: 10px;
            }
            button {
                padding: 0;
                outline: 0;
                display: block;
                border: none;
                margin: 0;
                cursor: pointer;
            }
            img {
                transition: $transition;
            }
            &:hover,
            &.slick-active {
                img {
                    opacity: 0.4;
                }
            }
        }
    }
    .slick-thumbs {
        display: none;
    }
    .row {
        margin: {
            left: -8px;
            right: -8px;
            top: -15px;
        }
        .col-lg-6 {
            padding: {
                left: 8px;
                right: 8px;
            }
            .single-products-details-image {
                margin-top: 15px;
            }
        }
    }
    .single-products-details-image {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }
}
.products-details-desc {
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
            weight: 700;
        }
    }
    .price {
        margin-bottom: 17px;
        color: $main-color;
        font: {
            size: $font-size;
            weight: 600;
        }
        .old-price {
            text-decoration: line-through;
            color: #828893;
            font-weight: normal;
            margin-left: 3px;
        }
    }
    .products-review {
        margin-bottom: 12px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 16.5px;

            i {
                color: #ffba0a;
                display: inline-block;
            }
        }
        .rating-count {
            display: inline-block;
            color: $black-color;
            border-bottom: 1px solid $black-color;
            line-height: initial;
            position: relative;
            top: -2px;
            font-weight: 600;

            &:hover {
                color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .products-info {
        list-style-type: none;
        padding-left: 0;
        margin: {
            top: 15px;
            bottom: 15px;
        }
        li {
            color: $black-color;
            margin-bottom: 8px;
            font: {
                size: $font-size;
            }
            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: $optional-color;
                margin-right: 2px;
            }
            a {
                display: inline-block;
                font-weight: 600;
                color: $black-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .products-info-btn {
        margin-bottom: 25px;

        a {
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            padding-left: 22px;
            line-height: initial;
            font: {
                size: 14px;
                weight: 600;
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 0;
                height: 1.5px;
                background: $main-color;
                transition: $transition;
            }
            i {
                font-size: 18px;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:hover {
                color: $main-color;

                &::before {
                    width: 100%;
                }
            }
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
    .products-add-to-cart {
        .input-counter {
            max-width: 120px;
            min-width: 120px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 40px;
                height: 100%;
                line-height: 50px;
                transition: $transition;
                font-size: 22px;

                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $main-color;
                }
            }
            input {
                height: 45px;
                color: $black-color;
                outline: 0;
                display: block;
                border: none;
                background-color: #ffffff;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: $black-color;
                }
            }
        }
    }
    .buy-checkbox-btn {
        margin-top: 20px;

        input {
            display: none;
        }
        .cbx {
            margin: auto;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;

            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);

                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: $transition;

                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: $white-color;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: $transition;
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: $main-color;
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: $transition;
                    }
                }
                &:last-child {
                    padding-left: 10px;
                    color: $black-color;
                    position: relative;
                    top: -1px;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $main-color;
                    }
                }
            }
        }
        .inp-cbx:checked + .cbx span:first-child {
            background: $main-color;
            border-color: $main-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked + .cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked + .cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
        .default-btn {
            display: block;
            text-align: center;
        }
    }
    .products-details-accordion {
        margin-top: 25px;

        .accordion-item {
            display: block;
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
            background: $white-color;
            margin-bottom: 15px;
            border-radius: 0;
            border: none;

            &:last-child {
                margin-bottom: 0;
            }
            .accordion-header {
                padding: 0;
                border-bottom: none;
                border-radius: 0 !important;
                background-color: $white-color;

                .accordion-button {
                    background-color: transparent;
                    padding: 14px 35px 12px 15px;
                    color: $black-color;
                    position: relative;
                    box-shadow: unset;
                    border-radius: 0;
                    display: block;
                    border: {
                        bottom: 1px solid #eeeeee;
                    }
                    font: {
                        size: 16px;
                        weight: 700;
                    }
                    i {
                        top: 50%;
                        right: 15px;
                        font-size: 20px;
                        position: absolute;
                        transform: rotate(180deg) translateY(50%);
                    }
                    &.collapsed {
                        border: {
                            bottom: {
                                color: transparent;
                            }
                        }
                        i {
                            transform: rotate(0) translateY(-50%);
                        }
                    }
                }
            }
            p {
                font-size: 14.5px;
            }
            ul,
            ol {
                padding-left: 0;
                list-style-type: none;
                margin-bottom: 0;

                li {
                    margin-bottom: 10px;
                    position: relative;
                    padding-left: 13px;
                    color: $optional-color;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 6px;
                        height: 6px;
                        background: $main-color;
                        border-radius: 50%;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .table {
                margin-bottom: 0;

                &.table-striped {
                    tbody {
                        tr {
                            td {
                                border-top: none;
                                vertical-align: middle;
                                white-space: nowrap;
                                padding: 10px 15px;
                                color: $black-color;
                                font-size: 14.5px;

                                &:first-child {
                                    font: {
                                        weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }
                &.table-bordered {
                    tbody {
                        tr {
                            td {
                                vertical-align: middle;
                                white-space: nowrap;
                                padding: 10px 15px;
                                color: $optional-color;
                                line-height: 1.7;
                                font-size: 14.5px;
                                border-color: #eeeeee;

                                &:first-child {
                                    color: $black-color;
                                    font: {
                                        weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }
                > :not(caption) > * > * {
                    border-bottom-width: 0;
                    box-shadow: unset;
                }
            }
            h3 {
                margin-bottom: 12px;
                font: {
                    size: 20px;
                    weight: 700;
                }
            }
            .products-review-form {
                margin-top: 10px;

                .review-title {
                    position: relative;

                    .rating {
                        display: inline-block;

                        .bx.bxs-star {
                            color: #ffba0a;
                        }
                        i {
                            color: #ebebeb;
                            font-size: 16px;
                            margin-right: -1px;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        display: inline-block;
                        padding-left: 5px;
                        line-height: initial;
                        position: relative;
                        top: -2px;
                    }
                    .default-btn {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
                .review-comments {
                    margin-top: 35px;

                    .review-item {
                        margin-top: 30px;
                        position: relative;
                        border-top: 1px dashed #eeeeee;
                        padding-top: 30px;

                        .rating {
                            .bx.bxs-star {
                                color: #ffba0a;
                            }
                            i {
                                font-size: 17px;
                                color: #ebebeb;
                                margin-right: -1px;
                            }
                        }
                        h3 {
                            font-size: 20px;
                            margin: {
                                top: 8px;
                                bottom: 8px;
                            }
                        }
                        span {
                            margin-bottom: 10px;
                            font-size: $font-size;
                            display: block;

                            strong {
                                font-weight: 500;
                            }
                        }
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
                .review-form {
                    margin-top: 30px;

                    h3 {
                        margin-bottom: 20px;
                    }
                    form {
                        .form-group {
                            margin-bottom: 15px;
                        }
                        .default-btn {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
.related-products {
    margin-top: 70px;
}

/*================================================
Funfacts Area CSS
=================================================*/
.funfacts-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/funfacts-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        z-index: -1;
        background-color: $black-color;
        opacity: 0.5;
    }
}
.single-funfacts {
    margin-bottom: 30px;
    position: relative;
    padding-left: 70px;

    i {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 50px;
        color: $white-color;
    }
    p {
        margin-bottom: 8px;
        line-height: initial;
        color: $white-color;
        font-weight: 500;
    }
    h3 {
        color: $white-color;
        margin-bottom: -13px;
        font: {
            size: 45px;
            weight: 700;
        }
        .odometer-formatting-mark {
            display: none;
        }
    }
}

/*================================================
App Download Area CSS
=================================================*/
.app-download-area {
    background-color: #f4f5fe;
    background: {
        image: url(assets/img/white-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
}
.app-download-image {
    text-align: center;
}
.app-download-content {
    .sub-title {
        display: inline-block;
        background-color: $main-color;
        color: $white-color;
        padding: 5px 25px 6px;
        border-radius: 35px;
        margin-bottom: 20px;
        font: {
            size: 14px;
            weight: 600;
            style: italic;
        }
    }
    h2 {
        margin-bottom: 14px;
        font: {
            size: 40px;
            weight: 700;
        }
    }
    .btn-box {
        margin-top: 25px;

        .play-store-btn {
            display: inline-block;
            background-color: $white-color;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            padding: 12px 25px 12px 74px;
            border-radius: 5px;
            position: relative;
            z-index: 1;
            color: #666786;
            font: {
                size: 13px;
                weight: 400;
            }
            span {
                display: block;
                color: $black-color;
                font: {
                    size: 20px;
                    weight: 700;
                }
            }
            img {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover,
            &:focus {
                transform: translateY(-5px);
            }
        }
        .apple-store-btn {
            margin-right: 10px;
            display: inline-block;
            background-color: $white-color;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            padding: 12px 25px 12px 74px;
            border-radius: 5px;
            position: relative;
            z-index: 1;
            color: #666786;
            font: {
                size: 13px;
                weight: 400;
            }
            span {
                display: block;
                color: $black-color;
                font: {
                    size: 20px;
                    weight: 700;
                }
            }
            img {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover,
            &:focus {
                transform: translateY(-5px);
            }
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
.single-team-member {
    display: flex;
    flex-wrap: wrap;
    align-items: center !important;
    margin: {
        bottom: 30px;
    }
    border: 1px solid #eee;

    .member-image {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        position: relative;
        img {
            border-radius: 3px;
        }
        .social {
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
            bottom: 0;
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;

            li {
                display: inline-block;

                a {
                    margin: 0 2px;
                    display: inline-block;

                    i {
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        border-radius: 50%;
                        background-color: $black-color;
                        color: $white-color;
                        font-size: 15px;
                        transition: $transition;

                        &.bxl-facebook {
                            background: #3b5998;
                        }
                        &.bxl-linkedin {
                            background: #0077b5;
                        }
                        &:hover {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .member-content {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        padding: {
            left: 23px;
            right: 23px;
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 22px;
                weight: 700;
            }
        }
        span {
            display: block;
            color: $main-color;
            margin-top: 10px;
            font: {
                size: 14px;
                weight: 600;
            }
        }
        p {
            font-size: 14px;
            line-height: 1.9;
            margin: {
                bottom: 0;
                top: 12px;
            }
        }
    }
    &:hover {
        .member-image {
            .social {
                bottom: 15px;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Listing Widget Sidebar CSS
=================================================*/
.listing-widget-area {
    .listing-widget {
        margin-bottom: 35px;

        .listing-widget-title {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 8px;
            border-bottom: 1px solid #eeeeee;
            font: {
                size: 20px;
                weight: 700;
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50px;
                height: 1px;
                bottom: -1px;
                background-color: $main-color;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .filter-list-widget {
        .selected-filters-wrap-list {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    margin-bottom: 9px;
                    font: {
                        size: 14px;
                        weight: 600;
                    }
                    a {
                        position: relative;
                        padding-left: 28px;
                        display: inline-block;

                        i {
                            display: inline-block;
                            background-color: $main-color;
                            width: 18px;
                            height: 18px;
                            color: $white-color;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 20px;
                            position: absolute;
                            left: 0;
                            top: 1px;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .delete-selected-filters {
                display: inline-block;
                margin-top: 15px;
                font: {
                    size: 14px;
                    weight: 600;
                }
                &:hover {
                    color: red;
                }
                span {
                    text-decoration: underline;
                }
            }
        }
    }
    .facilities-list-widget {
        .facilities-list-row {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 9px;

                a {
                    display: inline-block;
                    position: relative;
                    padding-left: 22px;
                    font: {
                        size: 14px;
                        weight: 600;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 2.5px;
                        width: 14px;
                        transition: $transition;
                        height: 14px;
                        border-radius: 50%;
                        border: 1px solid $black-color;
                    }
                    &:hover {
                        color: $main-color;

                        &::before {
                            border-color: $main-color;
                        }
                    }
                    &.active {
                        &::before {
                            background-color: $main-color;
                            border-color: $main-color;
                        }
                    }
                }
                &.active {
                    a {
                        &::before {
                            background-color: $main-color;
                            border-color: $main-color;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .price-list-widget {
        .collection-filter-by-price {
            margin-top: 20px;

            .irs-handle {
                cursor: pointer;
            }
            .irs--flat .irs-from,
            .irs--flat .irs-to,
            .irs--flat .irs-single {
                background-color: $main-color;
                padding: 1px 5px 1px 11.5px;

                &::before {
                    border-top-color: $main-color;
                }
                &::after {
                    content: "$";
                    position: absolute;
                    left: 5px;
                    top: 0.5px;
                }
            }
            .irs--flat .irs-bar {
                background-color: $main-color;
            }
            .irs--flat .irs-handle > i:first-child {
                background-color: $main-color;
            }
            .irs--flat .irs-min,
            .irs--flat .irs-max {
                padding: 1px 3px 1px 9.5px;

                &::before {
                    content: "$";
                    position: absolute;
                    left: 3px;
                    top: 0.5px;
                }
            }
        }
    }
    .categories-list-widget {
        .categories-list-row {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 9px;

                a {
                    display: inline-block;
                    position: relative;
                    padding-left: 22px;
                    font: {
                        size: 14px;
                        weight: 600;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 2.5px;
                        width: 14px;
                        transition: $transition;
                        height: 14px;
                        border-radius: 3px;
                        border: 1px solid $black-color;
                    }
                    &:hover {
                        color: $main-color;

                        &::before {
                            border-color: $main-color;
                        }
                    }
                    &.active {
                        &::before {
                            background-color: $main-color;
                            border-color: $main-color;
                        }
                    }
                }
                &.active {
                    a {
                        &::before {
                            background-color: $main-color;
                            border-color: $main-color;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .aside-trending-widget {
        .aside-trending-products {
            overflow: hidden;
            position: relative;
            text-align: center;

            .category {
                position: absolute;
                left: 0;
                transition: $transition;
                right: 0;
                background-color: $white-color;
                padding: 20px;
                bottom: 10px;
                margin: {
                    left: 10px;
                    right: 10px;
                }
                h3 {
                    text-transform: uppercase;
                    margin-bottom: 7px;
                    position: relative;
                    z-index: 1;
                    font: {
                        size: 20px;
                        weight: 700;
                    }
                }
                span {
                    color: $optional-color;
                    display: block;
                }
            }
            img {
                transition: $transition;
                width: 100%;
            }
            .link-btn {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
            }
            &:hover,
            &:focus {
                img {
                    transform: scale(1.3) rotate(4deg);
                }
            }
        }
    }
}

/*================================================
Modal CSS
=================================================*/
.shoppingCartModal {
    &.modal {
        &.right {
            .modal-dialog {
                position: fixed;
                margin: auto;
                width: 320px;
                height: 100%;
                transform: translate3d(0%, 0, 0);
            }
            .modal-content {
                height: 100%;
                overflow-y: auto;
            }
            &.fade {
                .modal-dialog {
                    right: -420px;
                    transition: opacity 0.3s linear, right 0.3s ease-out;
                }
                &.show {
                    .modal-dialog {
                        right: 0;
                    }
                }
            }
        }
    }
    .modal-content {
        border-radius: 0;
        border: none;

        .modal-body {
            padding: 25px 15px;

            h3 {
                font-size: 17px;
                text-transform: uppercase;
                margin-bottom: 25px;
            }
            .products-cart-content {
                .products-cart {
                    position: relative;
                    border-bottom: 1px dashed #eeeeee;
                    margin-bottom: 15px;
                    padding: {
                        left: 70px;
                        bottom: 15px;
                    }
                    .products-image {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 58px;

                        a {
                            display: block;
                        }
                    }
                    .products-content {
                        position: relative;

                        h3 {
                            font-size: 16px;
                            text-transform: initial;
                            margin-bottom: 5px;

                            a {
                                display: inline-block;
                            }
                        }
                        span {
                            font-size: 13.5px;
                            display: block;
                            margin-bottom: 5px;
                            color: $optional-color;
                        }
                        .products-price {
                            color: $main-color;
                            font: {
                                weight: 600;
                                size: 14px;
                            }
                        }
                        .remove-btn {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            display: inline-block;
                            color: $optional-color;
                            opacity: 0;
                            visibility: hidden;
                            transition: $transition;

                            &:hover {
                                color: red;
                            }
                        }
                        &:hover {
                            .remove-btn {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            .products-cart-subtotal {
                overflow: hidden;
                margin-bottom: 15px;
                border-bottom: 1px dashed #eeeeee;
                padding-bottom: 15px;

                span {
                    text-transform: uppercase;
                    display: inline-block;
                    font: {
                        size: 15px;
                        weight: 600;
                    }
                    &.subtotal {
                        float: right;
                    }
                }
            }
        }
    }
    .close {
        color: $black-color;
        font-size: 30px;
        outline: 0 !important;
        line-height: initial;
        transition: $transition;
        position: absolute;
        right: 15px;
        top: 17px;
        z-index: 1;

        &:not(:disabled):not(.disabled) {
            &:hover,
            &:focus {
                color: red;
                text-decoration: none;
                opacity: 1;
            }
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-post {
    position: relative;
    margin-bottom: 30px;

    .post-image {
        overflow: hidden;
        position: relative;
        border-radius: 5px 5px 0 0;

        a {
            display: block;
            border-radius: 5px 5px 0 0;

            img {
                border-radius: 5px 5px 0 0;
                transition: $transition;
            }
        }
        .date {
            position: absolute;
            right: -100%;
            top: 20px;
            background-color: $white-color;
            color: $black-color;
            padding: 5px 10px 6px;
            transition: $transition;
            visibility: hidden;
            font: {
                style: italic;
                size: 15px;
                weight: 600;
            }
        }
    }
    .post-content {
        margin-top: 20px;

        .category {
            display: block;
            margin-bottom: 12px;
            text-transform: uppercase;
            color: $main-color;
            font: {
                weight: 600;
                size: 14px;
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 23px;
                weight: 700;
            }
            a {
                display: inline-block;
            }
        }
        .details-btn {
            margin-top: 15px;
            display: inline-block;
            position: relative;
            color: $black-color;
            font: {
                size: 14px;
                weight: 600;
            }
            &::before {
                width: 100%;
                height: 1px;
                background-color: $optional-color;
                content: "";
                position: absolute;
                transition: $transition;
                left: 0;
                bottom: 0;
            }
            &:hover {
                color: $main-color;

                &::before {
                    width: 0;
                }
            }
        }
    }
    &:hover {
        .post-image {
            img {
                transform: scale(1.07) rotate(2deg);
            }
            .date {
                visibility: visible;
                right: 0;
            }
        }
    }
}
.blog-items {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));

    > * {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-image {
        img {
            width: 100%;
        }
    }
    .article-image-slides {
        &.owl-theme {
            .owl-nav {
                margin-top: 0;

                [class*="owl-"] {
                    color: #87a1c1;
                    font-size: 18px;
                    margin: 0;
                    padding: 0;
                    background: $white-color;
                    width: 45px;
                    height: 45px;
                    line-height: 40px;
                    position: absolute;
                    left: 20px;
                    transition: $transition;
                    top: 50%;
                    padding-right: 2px !important;
                    transform: translateY(-50%);
                    border: {
                        radius: 50%;
                        style: solid;
                        color: $white-color;
                        width: 1.5px;
                    }
                    &.owl-next {
                        left: auto;
                        right: 20px;
                        padding: {
                            right: 0 !important;
                            left: 2px !important;
                        }
                    }
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
    .article-video {
        iframe {
            width: 100%;
            height: 400px;
            border: none !important;
        }
    }
    .article-content {
        margin-top: 30px;

        .entry-meta {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    margin-right: 25px;
                    position: relative;
                    display: inline-block;
                    border-right: 1px solid #eeeeee;
                    font: {
                        weight: 500;
                        size: 16px;
                    }
                    padding: {
                        right: 20px;
                        left: 45px;
                    }
                    i {
                        font-size: 32px;
                        color: #cfcfcf;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    span {
                        display: block;
                        color: $main-color;
                        text-transform: uppercase;
                        margin-bottom: 2px;
                        font: {
                            size: 14px;
                            weight: 500;
                        }
                    }
                    a {
                        display: inline-block;
                        font: {
                            size: 16px;
                            weight: 600;
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                        margin-right: 0;
                        border-right: none;
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 17px;
                top: 25px;
            }
            font: {
                size: 24px;
                weight: 700;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 15px;
                position: relative;
                padding-left: 26px;
                color: $optional-color;

                i {
                    color: $main-color;
                    display: inline-block;
                    font-size: 20px;
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #eeeeee;
        padding-top: 30px;
        margin: {
            top: 30px;
        }
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $optional-color;

            span {
                display: inline-block;
                color: $black-color;
                margin-right: 3px;
                position: relative;
                top: 3px;
                font: {
                    size: 20px;
                }
            }
            a {
                display: inline-block;
                font: {
                    weight: 600;
                }
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;

            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: end;
                margin: {
                    bottom: 0;
                }
                li {
                    display: inline-block;

                    span {
                        display: inline-block;
                        margin-right: 3px;
                        font-weight: 600;
                        position: relative;
                        top: -2px;
                    }
                    a {
                        display: block;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        border-radius: 50%;
                        background-color: $main-color;
                        color: $white-color;
                        border: 1px solid $main-color;
                        text-align: center;
                        font-size: 17px;
                        margin-left: 5px;

                        &:hover,
                        &:focus {
                            color: $main-color;
                            background-color: transparent;
                        }
                        &.facebook {
                            background-color: #3b5998;
                            border-color: #3b5998;
                            color: $white-color;

                            &:hover,
                            &:focus {
                                color: #3b5998;
                                background-color: transparent;
                            }
                        }
                        &.twitter {
                            background-color: #1da1f2;
                            border-color: #1da1f2;
                            color: $white-color;

                            &:hover,
                            &:focus {
                                color: #1da1f2;
                                background-color: transparent;
                            }
                        }
                        &.linkedin {
                            background-color: #007bb5;
                            border-color: #007bb5;
                            color: $white-color;

                            &:hover,
                            &:focus {
                                color: #007bb5;
                                background-color: transparent;
                            }
                        }
                        &.instagram {
                            background-color: #c13584;
                            border-color: #c13584;
                            color: $white-color;

                            &:hover,
                            &:focus {
                                color: #c13584;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    .article-author {
        margin-top: 30px;
        border-radius: 5px;
        background: #fdfcfc;

        .author-profile-header {
            height: 115px;
            border-radius: 5px 5px 0 0;
            background: {
                color: $main-color;
                image: url(assets/img/bg.jpg);
                position: center center;
                size: cover;
                repeat: no-repeat;
            }
        }
        .author-profile {
            padding: 0 25px 25px;

            .author-profile-title {
                position: relative;
                z-index: 1;
                margin: {
                    bottom: 15px;
                    top: -45px;
                }
                img {
                    display: inline-block;
                    border: 3px solid $white-color;
                    width: 100px;
                    height: 100px;
                }
                .author-profile-title-details {
                    margin-top: 15px;

                    .author-profile-details {
                        h4 {
                            margin-bottom: 5px;
                            font: {
                                size: 20px;
                                weight: 700;
                            }
                        }
                        span {
                            color: $optional-color;
                        }
                    }
                }
                .author-profile-raque-profile {
                    a {
                        display: inline-block;
                        text-transform: capitalize;
                        font: {
                            weight: 600;
                            size: 14px;
                        }
                    }
                }
            }
        }
    }
}
blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 500;
            size: 22px !important;
        }
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        position: absolute;
        animation: fade-up 1.5s infinite linear;
        left: 50px;
        top: -50px;
        z-index: -1;
        content: "\ee33";
        font: {
            family: "boxicons" !important;
            weight: normal;
            style: normal;
            variant: normal;
            size: 135px;
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}
.louise-post-navigation {
    display: flex;
    flex-wrap: wrap;
    margin: {
        top: 30px;
    }
    padding: {
        top: 30px;
        bottom: 30px;
    }
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
}
.prev-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
        right: 15px;
    }
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-prev {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .prev-link-info-wrapper {
                color: $main-color;
            }
        }
    }
    .image-prev {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-right: 20px;
        position: relative;
        transition: $transition;

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: 16px;
                weight: 500;
            }
        }
    }
    .prev-link-info-wrapper {
        color: $black-color;
        transition: $transition;
    }
    .prev-title {
        display: inline-block;
        font: {
            weight: 600;
            size: 17px;
        }
    }
    .meta-wrapper {
        display: block;
        color: $optional-color;
        text-transform: capitalize;
        margin-top: 5px;
        font: {
            weight: 500;
            size: 14px;
        }
    }
}
.next-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: end;
    padding: {
        left: 15px;
    }
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-next {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .next-link-info-wrapper {
                color: $main-color;
            }
        }
    }
    .image-next {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-left: 20px;
        position: relative;
        transition: $transition;

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: 16px;
                weight: 500;
            }
        }
    }
    .next-link-info-wrapper {
        transition: $transition;
        color: $black-color;
    }
    .next-title {
        display: inline-block;
        font: {
            weight: 600;
            size: 17px;
        }
    }
    .meta-wrapper {
        display: block;
        color: $optional-color;
        text-transform: capitalize;
        margin-top: 5px;
        font: {
            weight: 500;
            size: 14px;
        }
    }
}
.comments-area {
    margin-top: 30px;

    .comments-title {
        margin: {
            bottom: 30px;
        }
        line-height: initial;
        font: {
            size: 24px;
            weight: 700;
        }
    }
    ol,
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px dashed #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px dashed #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 5px 20px;
                border-radius: 30px;
                text-transform: uppercase;
                font: {
                    size: 13px;
                    weight: 600;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: 16px;
        margin-bottom: 0.3em;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 600;
            }
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: 0.8em;
        color: $optional-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        }
        a {
            display: inline-block;
            color: $optional-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            font: {
                size: 24px;
                weight: 700;
            }
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 20px;
                top: 10px;
            }
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            float: left;
            width: 100%;
        }
        label {
            display: none;
        }
        input[type="date"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="week"],
        input[type="month"],
        input[type="text"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        input[type="search"],
        input[type="tel"],
        input[type="number"],
        textarea {
            display: block;
            width: 100%;
            background-color: red;
            border: none;
            padding: 0 0 0 15px;
            height: 50px;
            outline: 0;
            border-radius: 3px;
            color: $black-color;
            font: {
                weight: 500;
                size: $font-size;
            }
            &::placeholder {
                transition: $transition;
                color: $optional-color;
            }
            &:focus {
                &::placeholder {
                    color: transparent;
                }
            }
        }
        textarea {
            height: auto !important;
            padding-top: 15px;
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin: {
                bottom: 20px;
                top: 15px;
            }
            input {
                position: absolute;
                left: 0;
                top: 4px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $optional-color;
                position: relative;
                top: -3.5px;
                font: {
                    weight: normal;
                    size: 16px;
                }
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: green;
                border: none;
                color: $white-color;
                padding: 10px 30px 9px;
                display: inline-block;
                cursor: pointer;
                text-transform: uppercase;
                transition: $transition;
                border-radius: 0;
                font: {
                    weight: 600;
                    size: 14.5px;
                }
                &:hover,
                &:focus {
                    color: $white-color;
                    background: $main-color2;
                }
            }
        }
    }
}

/*================================================
Instagram Area CSS
=================================================*/
.instagram-area {
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }
    }
}
.instagram-title {
    margin-bottom: 50px;
    text-align: center;

    a {
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding-left: 32px;
        font: {
            size: $font-size;
            weight: 300;
        }
        i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 25px;
        }
    }
}
.single-instagram-post {
    position: relative;
    overflow: hidden;
    z-index: 1;

    i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        color: $white-color;
        font-size: 30px;
        border-radius: 2px;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
        margin-top: 10px;
        background: radial-gradient(
            circle at 30% 107%,
            #fdf497 0,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
        );
    }
    .link-btn {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $black-color;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        i {
            margin-top: 0;
            opacity: 1;
            visibility: visible;
        }
        &::before {
            visibility: visible;
            opacity: 0.5;
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    padding: {
        top: 250px;
        bottom: 130px;
    }
    background: {
        color: $black-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    &.page-title-bg1 {
        background-image: url(assets/img/page-title1.jpg);
    }
    &.page-title-bg2 {
        background-image: url(assets/img/page-title2.jpg);
    }
    &.page-title-bg3 {
        background-image: url(assets/img/page-title3.jpg);
    }
    &.page-title-bg4 {
        background-image: url(assets/img/page-title4.jpg);
    }
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $black-color;
        opacity: 0.8;
    }
}
.page-title-content {
    text-align: center;

    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 40px;
            weight: 700;
        }
    }
}

/*================================================
Products Area CSS
=================================================*/
.single-products-box {
    position: relative;
    margin-bottom: 30px;

    .products-image {
        text-align: center;
        position: relative;
        overflow: hidden;

        a {
            position: relative;
            display: block;

            img {
                transition: 0.8s;
            }
            .hover-image {
                position: absolute;
                left: 0;
                right: 0;
                opacity: 0;
                visibility: hidden;
                transform: scale(1.07);
            }
        }
        .sale-tag {
            position: absolute;
            left: 20px;
            top: 20px;
            background-color: red;
            color: $white-color;
            width: 55px;
            height: 55px;
            line-height: 55px;
            text-align: center;
            border-radius: 50%;
            z-index: 2;
            text-transform: uppercase;
            font: {
                weight: 700;
                size: 14px;
            }
        }
        .new-tag {
            position: absolute;
            left: 20px;
            top: 20px;
            background-color: green;
            color: $white-color;
            text-align: center;
            z-index: 2;
            padding: 1px 7px;
            text-transform: uppercase;
            font: {
                weight: 700;
                size: 14px;
            }
        }
    }
    .products-content {
        margin-top: 20px;
        position: relative;

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 600;
            }
            a {
                display: inline-block;
                color: $black-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
        .price {
            margin-top: 8px;
            transition: $transition;
            font: {
                size: $font-size;
                weight: 600;
            }
            .old-price {
                text-decoration: line-through;
                color: #999999;
                font-weight: 500;
            }
        }
        .star-rating {
            position: absolute;
            right: 0;
            bottom: 0;
            color: #f49f0b;

            i {
                display: inline-block;
                margin-left: -2px;
            }
        }
        .add-to-cart {
            position: absolute;
            left: 0;
            bottom: 0;
            text-transform: uppercase;
            transition: $transition;
            display: inline-block;
            color: $optional-color;
            opacity: 0;
            visibility: hidden;
            transform: translateX(20px);
            font: {
                size: 14px;
                weight: 600;
            }
            &:hover {
                color: $main-color;
            }
        }
    }
    &:hover {
        .products-content {
            .price {
                opacity: 0;
                visibility: hidden;
            }
            .add-to-cart {
                transform: translateX(0);
                opacity: 1;
                visibility: visible;
            }
        }
        .products-image {
            a {
                .main-image {
                    opacity: 0;
                    visibility: hidden;
                    transform: scale(1.07);
                }
                .hover-image {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }
            .products-button {
                ul {
                    li {
                        transform: translateX(0);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
    .widget {
        margin-bottom: 35px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 8px;
            border-bottom: 1px solid #eeeeee;
            font: {
                size: 22px;
                weight: 700;
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50px;
                height: 1px;
                bottom: -1px;
                background-color: $main-color;
            }
        }
    }
    .widget_search {
        form {
            position: relative;

            .screen-reader-text {
                display: none;
            }
            label {
                display: block;
                margin-bottom: 0;
            }
            .search-field {
                height: 50px;
                color: $black-color;
                background-color: #f2f4f5;
                display: block;
                width: 100%;
                border-radius: 5px;
                padding: 0 0 0 15px;
                border: none;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 500;
                }
                &::placeholder {
                    color: $optional-color;
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                border: none;
                background-color: $main-color;
                color: $white-color;
                height: 40px;
                width: 40px;
                position: absolute;
                right: 5px;
                transition: $transition;
                border-radius: 5px;
                top: 5px;
                font-size: 20px;

                &:hover,
                &:focus {
                    background-color: $main-color2;
                    color: $white-color;
                }
            }
        }
    }
    .widget_louise_posts_thumb {
        position: relative;
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 5px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(assets/img/blog/img7.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog/img8.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog/img5.jpg);
                    }
                }
                &::before,
                &::after {
                    transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
                    content: "";
                    background-color: $white-color;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $optional-color;
                    text-transform: uppercase;
                    margin: {
                        top: -2px;
                        bottom: 5px;
                    }
                    font: {
                        size: 12px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 16px;
                        weight: 600;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }
            &:hover {
                .thumb {
                    &::before,
                    &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                overflow: hidden;
                color: $black-color;
                padding-left: 18px;
                font: {
                    weight: 600;
                    size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 8px;
                    width: 8px;
                    content: "";
                    border-radius: 50%;
                    left: 0;
                    top: 7px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: inline-block;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    color: $optional-color;
                    font-size: 14px;
                    display: inline-block;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 17px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            background-color: #f6f6f6;
            color: $black-color;
            padding: 7px 15px;
            border: none;
            border-radius: 3px;
            font: {
                weight: 600;
                size: 13.5px !important;
            }
            margin: {
                top: 8px;
                right: 8px;
            }
            &:hover,
            &:focus {
                color: $white-color;
                background-color: $main-color;
            }
        }
    }
    .widget_instagram {
        ul {
            padding-left: 0;
            list-style-type: none;
            flex-wrap: wrap;
            display: flex;
            margin: {
                bottom: -10px;
                left: -5px;
                right: -5px;
            }
            li {
                flex: 0 0 33.3333333333%;
                max-width: 33.3333333333%;
                padding: {
                    bottom: 10px;
                    right: 5px;
                    left: 5px;
                }
                a {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $main-color;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: 0.7;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .widget_contact {
        position: relative;
        z-index: 1;
        border-radius: 5px;
        height: 400px;
        text-align: center;
        background: {
            image: url(assets/img/main-banner1.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
        &::before {
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px;
            background-color: $main-color;
            opacity: 0.4;
            z-index: -1;
        }
        .text {
            position: absolute;
            left: 0;
            bottom: 25px;
            right: 0;

            .icon {
                width: 65px;
                height: 65px;
                border: 2px dashed $main-color;
                position: relative;
                border-radius: 50%;
                font-size: 35px;
                transition: $transition;
                background-color: $white-color;
                color: $main-color;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 17px;
                }
                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            span {
                display: block;
                color: $white-color;
                margin-bottom: 8px;
                font: {
                    size: 16px;
                    weight: 600;
                }
            }
            a {
                display: inline-block;
                color: $white-color;
                font: {
                    weight: 700;
                    size: 25px;
                }
            }
        }
    }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;
    background: {
        image: url(assets/img/coming-soon-bg.jpg);
        position: top center;
        size: cover;
        repeat: no-repeat;
    }
}
.coming-soon-content {
    max-width: 700px;
    background: $white-color;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    padding: 40px 60px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    margin: {
        left: auto;
        right: auto;
    }
    .logo {
        display: inline-block;
    }
    h2 {
        font: {
            size: 40px;
            weight: 700;
        }
        margin: {
            top: 30px;
            bottom: 0;
        }
    }
    #timer {
        margin-top: 40px;

        div {
            background-color: #333333;
            color: $white-color;
            width: 100px;
            height: 105px;
            border-radius: 5px;
            font: {
                size: 40px;
                weight: 700;
                family: $font-family2;
            }
            margin: {
                left: 10px;
                right: 10px;
            }
            span {
                display: block;
                margin-top: -2px;
                font: {
                    size: 16px;
                    weight: 500;
                }
            }
        }
    }
    form {
        position: relative;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
            top: 50px;
        }
        .form-group {
            margin-bottom: 25px;
            width: 100%;
            position: relative;

            .label-title {
                margin-bottom: 0;
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                pointer-events: none;
                width: 100%;
                height: 100%;
                color: $black-color;

                i {
                    position: absolute;
                    left: 0;
                    transition: $transition;
                    top: 9px;
                    font: {
                        size: 22px;
                    }
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    transition: $transition;
                    background: $main-color;
                }
            }
            .input-newsletter {
                border-radius: 0;
                border: none;
                border-bottom: 2px solid #eeeeee;
                padding: 0 0 0 32px;
                color: $black-color;
                height: 45px;
                display: block;
                width: 100%;
                transition: $transition;
                font: {
                    size: 16px;
                    weight: 400;
                }
                &::placeholder {
                    color: #a1a1a1;
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: white;
                    }
                }
            }
        }
        .default-btn {
            border-radius: 0;
        }
        .validation-danger {
            margin-top: 15px;
            color: red;
        }
        .validation-success {
            margin-top: 15px;
        }
        p {
            margin: {
                bottom: 0;
                top: 20px;
            }
        }
    }
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
    table {
        margin-bottom: 0;

        thead {
            tr {
                border: none;

                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 0 0 15px;
                    text-transform: uppercase;
                    border: none;
                    white-space: nowrap;
                    border: {
                        color: #eaedff;
                    }
                    font: {
                        size: $font-size;
                        weight: 600;
                    }
                }
            }
        }
        tbody {
            tr {
                border: {
                    color: #eaedff;
                }
                td {
                    vertical-align: middle;
                    color: $optional-color;
                    white-space: nowrap;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 15px;
                        bottom: 15px;
                    }
                    border: {
                        color: #eaedff;
                        left: none;
                        right: none;
                    }
                    &.product-thumbnail {
                        a {
                            display: inline-block;

                            img {
                                width: 100px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            display: inline-block;
                            font: {
                                size: 16px;
                                weight: 600;
                            }
                        }
                        ul {
                            padding-left: 0;
                            list-style-type: none;
                            margin: {
                                top: 10px;
                                bottom: 0;
                            }
                            li {
                                margin-bottom: 6px;
                                font-size: 13.5px;

                                span {
                                    display: inline;
                                    color: $black-color;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;

                        .subtotal-amount {
                            color: $black-color;
                            font-weight: 600;
                        }
                        .remove {
                            color: red;
                            float: right;
                            display: block;
                            position: relative;
                            top: -1px;
                            font-size: 18px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            text-align: center;
                            display: inline-block;
                            position: relative;

                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 50px;
                                transition: $transition;
                                font-size: 22px;

                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            input {
                                height: 45px;
                                color: $black-color;
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                font: {
                                    size: 17px;
                                    weight: 600;
                                }
                                &::placeholder {
                                    color: $black-color;
                                }
                            }
                        }
                    }
                    .unit-amount {
                        color: $black-color;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .table > :not(:first-child) {
        border-top: none;
    }
}
.cart-buttons {
    margin-top: 30px;
}
.cart-totals {
    background: $white-color;
    padding: 40px;
    max-width: 500px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: {
        left: auto;
        top: 50px;
        right: auto;
    }
    h3 {
        margin-bottom: 20px;
        font: {
            size: 25px;
            weight: 700;
        }
    }
    ul {
        padding-left: 0;
        margin: 0 0 25px;
        list-style-type: none;

        li {
            border: 1px solid #eaedff;
            padding: 10px 15px;
            color: $black-color;
            overflow: hidden;
            font: {
                weight: 600;
            }
            &:first-child {
                border: {
                    bottom: none;
                }
            }
            &:last-child {
                font: {
                    size: 20px;
                }
                border: {
                    top: none;
                }
                span {
                    color: $black-color;
                }
            }
            span {
                float: right;
                font: {
                    weight: normal;
                }
            }
        }
    }
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 15px 20px;
    border-top: 3px solid $main-color;
    position: relative;
    margin-bottom: 40px;

    i {
        color: $main-color;
        margin-right: 2px;
        font-size: 20px;
        position: relative;
        top: 3px;
    }
    span {
        display: inline-block;
        color: $main-color;
        font: {
            weight: 500;
            size: 16px;
        }
        a {
            display: inline-block;
            color: $black-color;

            &:hover,
            &:focus {
                color: $main-color;
            }
        }
    }
}
.billing-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 22px;
            weight: 700;
        }
        &::before {
            content: "";
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .form-group {
        margin-bottom: 25px;

        label {
            display: block;
            margin-bottom: 8px;
            text-transform: uppercase;
            font: {
                weight: 600;
                size: 14px;
            }
            .required {
                color: red;
            }
        }
        .nice-select {
            float: unset;
            line-height: 48px;
            color: $black-color;
            border: none;
            background-color: #f5f5f5 !important;
            font: {
                weight: normal;
                size: $font-size;
            }
            padding: {
                top: 0;
                bottom: 0;
            }
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: $transition;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: white !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: white !important;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
    }
    .form-check {
        margin-bottom: 20px;

        .form-check-label {
            color: $black-color;
        }
        label {
            position: relative;
            left: -3px;
            top: 1px;
            font: {
                weight: 500;
            }
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
.order-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 22px;
            weight: 700;
        }
        &::before {
            content: "";
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .order-table {
        table {
            margin-bottom: 0;

            thead {
                tr {
                    border: {
                        color: #eaedff;
                    }
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        padding: 12px 20px 10px;
                        font: {
                            weight: 700;
                            size: 17px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    border: {
                        color: #eaedff;
                    }
                    td {
                        vertical-align: middle;
                        border-color: #eaedff;
                        font-size: $font-size;
                        padding: {
                            left: 20px;
                            right: 20px;
                            top: 15px;
                            bottom: 13px;
                        }
                        &.product-name {
                            a {
                                display: inline-block;
                                font: {
                                    weight: 500;
                                }
                            }
                        }
                        &.order-subtotal,
                        &.order-shipping,
                        &.total-price {
                            span {
                                color: $black-color;
                                font-weight: 600;
                            }
                        }
                        &.shipping-price,
                        &.order-subtotal-price,
                        &.product-subtotal {
                            font-weight: 600;
                            color: $black-color;
                        }
                    }
                }
            }
        }
        .table > :not(:first-child) {
            border-top: none;
        }
    }
    .payment-box {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;

        .payment-method {
            p {
                [type="radio"] {
                    &:checked,
                    &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked,
                    &:not(:checked) {
                        + label {
                            padding-left: 27px;
                            cursor: pointer;
                            display: block;
                            color: $black-color;
                            position: relative;
                            margin-bottom: 8px;
                            font: {
                                weight: 600;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 5px;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: $white-color;
                            }
                            &::after {
                                content: "";
                                width: 12px;
                                height: 12px;
                                background: $main-color;
                                position: absolute;
                                top: 8px;
                                left: 3px;
                                border-radius: 50%;
                                transition: $transition;
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        + label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        + label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .default-btn {
            margin-top: 20px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
            display: block;
            text-align: center;
        }
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion-tab {
    .tabs {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 60px;
        text-align: center;

        li {
            margin: {
                left: 10px;
                right: 10px;
            }
            a {
                color: $black-color;
                text-decoration: none;
                border-radius: 5px;
                padding: 20px;
                display: block;
                box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
                font: {
                    size: 17px;
                    weight: 600;
                }
                i {
                    font-size: 30px;
                }
                span {
                    display: block;
                    margin-top: 8px;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
            &.current {
                a {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.faq-accordion {
    .accordion-item {
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
        background: $white-color;
        margin-bottom: 15px;
        border-radius: 0;
        display: block;
        border: none;

        &:last-child {
            margin-bottom: 0;
        }
        .accordion-header {
            padding: 0;
            border-bottom: none;
            border-radius: 0 !important;
            background-color: $white-color;

            .accordion-button {
                border-bottom: 1px solid #eeeeee;
                background-color: transparent;
                box-shadow: unset !important;
                padding: 14px 40px 12px 20px;
                color: $black-color;
                position: relative;
                border-radius: 0;
                display: block;
                font: {
                    size: 16px;
                    weight: 600;
                }
                i {
                    top: 50%;
                    right: 20px;
                    font-size: 20px;
                    position: absolute;
                    transform: rotate(180deg) translateY(50%);
                }
                &.collapsed {
                    border: {
                        bottom: {
                            color: transparent;
                        }
                    }
                    i {
                        transform: rotate(0) translateY(-50%);
                    }
                }
            }
        }
        .collapse {
            .card-body {
                position: relative;
                padding: 20px;
            }
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;

            li {
                color: $optional-color;
                position: relative;
                margin-bottom: 12px;
                line-height: 25px;
                padding-left: 15px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    background: $main-color;
                    border-radius: 50%;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*================================================
Login Area CSS
=================================================*/
.login-image {
    height: 100%;
    width: 100%;
    background: {
        image: url(assets/img/login-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
}
.login-content {
    height: 100vh;

    .login-form {
        text-align: center;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
        }
        .logo {
            margin-bottom: 25px;

            a {
                display: inline-block;
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 40px;
                weight: 700;
            }
        }
        p {
            margin: {
                top: 10px;
                bottom: 0;
            }
            a {
                display: inline-block;
            }
        }
        form {
            margin-top: 35px;

            .form-control {
                background-color: #e0e7ff;
                color: $black-color;
                border: none;
                transition: $transition;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
                height: 55px;
                font: {
                    size: $font-size;
                }
                &::placeholder {
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: white;
                    }
                }
            }
            button {
                transition: $transition;
                display: block;
                width: 100%;
                padding: 14px 25px 13px;
                text-transform: uppercase;
                position: relative;
                background-color: $main-color;
                color: $white-color;
                box-shadow: 0px 5px 28.5px 1.5px rgba(8, 141, 211, 0.15);
                border: {
                    width: 2px;
                    style: solid;
                    color: $main-color;
                    radius: 5px;
                }
                font: {
                    size: 14.5px;
                    weight: 700;
                }
                &:hover,
                &:focus {
                    background-color: $main-color2;
                    color: $white-color;
                    border-color: $main-color2;
                }
            }
            .forgot-password {
                text-align: end;
                margin-top: 20px;

                a {
                    display: inline-block;
                    color: $main-color;
                    text-decoration: underline;
                    font-weight: 600;

                    &:hover {
                        color: $main-color2;
                    }
                }
            }
            .connect-with-social {
                margin-top: 20px;

                button {
                    display: block;
                    width: 100%;
                    position: relative;
                    border: 1px solid $main-color;
                    background-color: transparent;
                    transition: $transition;
                    padding: 12px 30px;
                    border-radius: 2px;
                    color: $main-color;
                    box-shadow: unset;
                    font: {
                        weight: 600;
                        size: 14.5px;
                    }
                    i {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 15px;
                        font-size: 20px;
                    }
                    &.facebook {
                        border-color: #3b5998;
                        color: #3b5998;

                        &:hover {
                            background-color: #3b5998;
                            color: $white-color;
                            border-color: #3b5998;
                        }
                    }
                    &.twitter {
                        margin-top: 15px;
                        border-color: #1da1f2;
                        color: #1da1f2;

                        &:hover {
                            background-color: #1da1f2;
                            color: $white-color;
                            border-color: #1da1f2;
                        }
                    }
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}

/*================================================
Register Area CSS
=================================================*/
.register-image {
    height: 100%;
    width: 100%;
    background: {
        image: url(assets/img/register-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    img {
        display: none;
    }
}
.register-content {
    height: 100vh;

    .register-form {
        // text-align: center;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
        }
        .logo {
            margin-bottom: 25px;

            a {
                display: inline-block;
            }
        }
        h3 {
            margin-bottom: 0;
            font: {
                size: 40px;
                weight: 700;
            }
        }
        p {
            margin: {
                top: 10px;
                bottom: 0;
            }
            a {
                display: inline-block;
            }
        }
        form {
            margin-top: 35px;

            .form-control {
                background-color: rgba(224, 231, 255, 0.2);
                fill: rgba(224, 231, 255, 0.2);

                stroke-width: 1px;
                stroke: #e0e7ff;

                color: $black-color;
                border: 1px solid rgba(224, 231, 255);
                border-radius: 4px;
                transition: $transition;
                // box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
                height: 45px;
                font: {
                    size: $font-size;
                }
                &::placeholder {
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                transition: $transition;
                display: block;
                width: 100%;
                padding: 14px 25px 13px;
                text-transform: uppercase;
                position: relative;
                background-color: $main-color;
                color: $white-color;
                box-shadow: 0px 5px 28.5px 1.5px rgba(8, 141, 211, 0.15);
                border: {
                    width: 2px;
                    style: solid;
                    color: $main-color;
                    radius: 5px;
                }
                font: {
                    size: 14.5px;
                    weight: 700;
                }
                &:hover,
                &:focus {
                    background-color: $main-color2;
                    color: $white-color;
                    border-color: $main-color2;
                }
            }
        }
    }
}

/*================================================
Error 404 Area CSS
=================================================*/
.error-404-area {
    z-index: 1;
    background: #f8f8f8;
    position: relative;
    height: 100vh;

    .notfound {
        position: relative;
        max-width: 520px;
        z-index: 1;
        text-align: center;
        margin: {
            left: auto;
            right: auto;
        }
        .notfound-bg {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;

            div {
                width: 100%;
                background: $white-color;
                border-radius: 90px;
                height: 125px;

                &:nth-child(1) {
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                }
                &:nth-child(2) {
                    transform: scale(1.3);
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                    position: relative;
                    z-index: 10;
                }
                &:nth-child(3) {
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                    position: relative;
                    z-index: 90;
                }
            }
        }
        h1 {
            background: url(assets/img/main-banner1.jpg) no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: cover;
            background-position: center;
            text-transform: uppercase;
            font: {
                size: 100px;
                weight: 700;
            }
            margin: {
                top: 0;
                bottom: 15px;
            }
        }
        h3 {
            margin-bottom: 12px;
            font: {
                weight: 700;
                size: 22px;
            }
        }
        p {
            color: #6084a4;
            margin-bottom: 20px;
            line-height: 1.7;
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info {
    h3 {
        margin-bottom: 15px;
        font: {
            size: 23px;
            weight: 700;
        }
    }
    p {
        a {
            display: inline;
            font-weight: 500;
        }
    }
    .contact-list {
        padding-left: 0;
        margin-bottom: 25px;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            position: relative;
            padding-left: 25px;

            i {
                position: absolute;
                left: 0;
                top: 2px;
                color: $main-color;
                font-size: 20px;
            }
            a {
                display: inline-block;
                font-weight: 600;
                position: relative;

                &:hover {
                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    transition: $transition;
                    background-color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .opening-hours {
        padding-left: 0;
        margin-bottom: 25px;
        list-style-type: none;

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
        }
        li {
            display: inline-block;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
            a {
                width: 30px;
                height: 30px;
                text-align: center;
                display: block;
                color: $main-color;
                background-color: #f4f4f4;
                font-size: 18px;
                position: relative;
                border-radius: 2px;

                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-49%);
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.contact-form {
    h3 {
        margin-bottom: 15px;
        font: {
            size: 23px;
            weight: 700;
        }
    }
    form {
        .form-group {
            label {
                display: block;
                margin-bottom: 10px;
                text-transform: uppercase;
                font: {
                    size: 14px;
                    weight: 600;
                }
                span {
                    color: red;
                }
            }
        }
    }
}
#map {
    iframe {
        width: 100%;
        height: 500px;
        border: none;
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    padding-top: 50px;
    background-color: $black-color;
    position: relative;
    z-index: 1;
}
.single-footer-widget {
    margin-bottom: 30px;

    h3 {
        color: $white-color;
        margin-bottom: 25px;
        padding-bottom: 5px;
        position: relative;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        font: {
            size: 23px;
            weight: 700;
        }
        &::before {
            width: 50px;
            height: 1px;
            bottom: -1px;
            content: "";
            position: absolute;
            left: 0;
            background-color: $main-color;
        }
    }
    p {
        color: #c7cad4;
    }
    .about-the-store {
        p {
            font: {
                weight: 600;
            }
        }
        .footer-contact-info {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 12px;
                color: #c7cad4;
                position: relative;
                padding-left: 25px;

                i {
                    position: absolute;
                    left: 0;
                    top: 2px;
                    font-size: 18px;
                    color: $white-color;
                }
                a {
                    display: inline-block;
                    color: #c7cad4;
                    font-weight: 600;
                    position: relative;

                    &:hover {
                        color: $main-color;

                        &::before {
                            width: 100%;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 1px;
                        transition: $transition;
                        background-color: $main-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .social-link {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 20px;
            bottom: 0;
        }
        li {
            display: inline-block;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
            a {
                width: 40px;
                height: 30px;
                text-align: center;
                color: $black-color;
                // background-color: #c7cad4;
                font-size: 18px;
                position: relative;
                border-radius: 2px;

                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-48%);
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
    .quick-links {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            color: #c7cad4;

            a {
                display: inline-block;
                color: #c7cad4;
                font-weight: 600;
                position: relative;

                &:hover {
                    color: $main-color;

                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    transition: $transition;
                    background-color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .customer-support {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            color: #c7cad4;

            a {
                display: inline-block;
                color: #c7cad4;
                font-weight: 600;
                position: relative;

                &:hover {
                    color: $main-color;

                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    transition: $transition;
                    background-color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-newsletter-box {
        .newsletter-form {
            position: relative;
            margin-top: -5px;

            label {
                display: block;
                color: $white-color;
                font-weight: 600;
                margin-bottom: 10px;
            }
            .input-newsletter {
                width: 100%;
                background-color: transparent;
                border: 1px solid #848282;
                border-radius: 0;
                height: 45px;
                padding-left: 10px;
                color: $white-color;
                font-size: 14px;
                border-radius: 0.25rem;

                &::placeholder {
                    transition: $transition;
                    color: #cccccc;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                display: block;
                margin-top: 0.8rem;
                width: 100%;
                border-radius: 0.25rem;
                background-color: $main-color;
                color: $white-color;
                border: none;
                padding: 12px 25px;
                transition: $transition;
                font: {
                    weight: 600;
                    size: $font-size;
                }
                &:hover {
                    color: $black-color;
                    background-color: $white-color;
                }
            }
            #validator-newsletter {
                position: absolute;
                left: 0;
                bottom: -32px;
                color: $white-color !important;
            }
        }
    }
}
.footer-bottom-area {
    margin-top: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: {
        top: 25px;
        bottom: 25px;
    }
    p {
        line-height: initial;
        margin-bottom: 0;
        color: #c7cad4;
        font: {
            weight: 500;
        }
        i {
            position: relative;
            top: 1px;
        }
        a {
            display: inline-block;
            color: $white-color;
            font-weight: 600;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                transition: $transition;
                height: 1px;
                background-color: $main-color;
            }
            &:hover {
                color: $main-color;

                &::before {
                    width: 100%;
                }
            }
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        text-align: end;

        li {
            display: inline-block;
            position: relative;
            margin-left: 18px;

            &:first-child {
                margin-left: 0;

                &::before {
                    display: none;
                }
            }
            a {
                display: block;
                color: #c7cad4;

                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                content: "";
                position: absolute;
                left: -10px;
                top: 6px;
                height: 12px;
                width: 1px;
                background-color: #c7cad4;
            }
        }
    }
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
    z-index: -1;

    .line {
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        background: rgba(255, 255, 255, 0.1);
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            position: absolute;
            height: 15vh;
            width: 100%;
            top: -50%;
            left: 0;
            background: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0) 0%,
                $white-color 75%,
                $white-color 100%
            );
            animation: run 7s 0s infinite;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
        }
        &:nth-child(1) {
            margin-left: -25%;

            &::after {
                animation-delay: 2s;
            }
        }
        &:nth-child(3) {
            margin-left: 25%;

            &::after {
                animation-delay: 2.5s;
            }
        }
    }
}
@keyframes run {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: -100px;
    right: 15px;
    color: $black-color;
    background-color: $white-color;
    z-index: 4;
    width: 45px;
    text-align: center;
    height: 45px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 40px;
    transition: 0.9s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    &.active {
        opacity: 1;
        visibility: visible;
        bottom: 15px;
    }
    i {
        position: absolute;
        right: 0;
        left: 0;
        top: 70%;
        transform: translateY(-70%);
        text-align: center;
        margin: {
            left: auto;
            right: auto;
        }
    }
    &:hover {
        background-color: $main-color;
        color: $white-color;
        transition: $transition;
        transform: translateY(-5px);
    }
}

.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
.col-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
}

.row-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.row-flex:not(.align-start):not(.align-end) {
    align-items: center;
    align-content: center;
}

.row-flex.align-start {
    align-items: flex-start;
    align-content: flex-start;
}

.row-flex.align-end {
    align-items: flex-end;
    align-content: flex-end;
}

.flex-space {
    flex: 20;
}

@media only screen and (max-width: 767px) {
    .top-space-responsive {
        margin-top: 15px;
    }
    .container {
        // margin-top: 3% !important;
    }

    .section-title {
        margin-top: 15% !important;
    }
}

.nowrap {
    white-space: nowrap;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

$custom-color: (
    50: #12b6df,
    100: #12b6df,
    200: #12b6df,
    300: #12b6df,
    400: #12b6df,
    500: #12b6df,
    600: #12b6df,
    700: #12b6df,
    800: #12b6df,
    900: #12b6df,
    A100: #12b6df,
    A200: #12b6df,
    A400: #12b6df,
    A700: #12b6df,
    contrast: (
        50: black,
        100: black,
        200: black,
        300: black,
        400: black,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: black,
        A200: white,
        A400: white,
        A700: white,
    ),
);

// Use the custom color map as the primary and accent palettes
$custom-primary: mat-palette($custom-color, 500);
$custom-accent: mat-palette($custom-color, A200);

// Create the theme using the custom palettes
$custom-theme: mat-light-theme(
    (
        color: (
            primary: $custom-primary,
            accent: $custom-accent,
        ),
    )
);

// Include the theme in the global styles
@include angular-material-theme($custom-theme);
.red-star {
    color: red;
    font-size: 10px;
}
button:disabled {
    background-color: #ccc !important; /* Light gray background */
    color: #666; /* Gray text color */
    border: none !important; /* No border */
    cursor: not-allowed; /* Change the cursor to a 'not-allowed' symbol */
    opacity: 0.6; /* Make the button slightly transparent */
}
.row {
    justify-content: space-evenly;
}
.green-snackbar {
    background: rgb(65, 252, 134);
    color: white !important;
}
.green-snackbar button {
    background-color: rgb(65, 252, 134);
    color: white;
    border: none;
}
.red-snackbar {
    background: #f44336;
    color: white;
}
.red-snackbar button {
    background-color: #f44336;
    color: white !important;
    border: none;
}
.link{
    color: #12B6DF;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 0.45rem;
}
.custom-snack-bar-error{
    background-color: red;
    color:white
}

html{
    background-color: white;
}

.tri-right.border.right-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -40px;
  top: 30px;
	bottom: auto;
	border: 20px solid;
	border-color: #666 transparent transparent #666;
}
::ng-deep [dir="rtl"] .tri-right.right-in:after{
  content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: -20px !important;
    right: auto !important;
    top: 38px;
    bottom: auto;
    border: 12px solid;

	border-color: #F5F5FA   #F5F5FA transparent transparent!important}
    [dir = "rtl"] .talktext{
        text-align: right ;

    }
    .bottom{
        margin-top:17rem
    }
    .img{
        height:77vh;
        width: auto;
            }
.tri-right.right-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -20px;
  top: 38px;
	bottom: auto;
	border: 12px solid;
	border-color:transparent transparent #F5F5FA   #F5F5FA }
  


/* talk bubble contents */
.talktext{
  padding: 2rem;
	text-align: left;
  line-height: 1.5em;
//   max-height: 49vh;
  // max-width: fit-content;
  padding: 1em;
  height:auto;
	text-align: left;
  line-height: 1.5em;
}
.text{
    // margin-left: 3rem;
    margin-bottom: 2rem;
}
.talk-bubble {
	// margin: 40px;
  display: inline-block;
  position: relative;
	// min-width: 200px;
  // width: 200px;

  max-width: 140rem;
//   max-height: 50vh;
  min-height: 11vh;
  min-width:30vh;
  border-radius: 40px;
//   box-shadow: -2px -2px 4px 0px rgba(255, 255, 255, 0.20) inset, 2px 2px 4px 0px rgba(170, 170, 204, 0.10) inset, 5px 5px 10px 0px rgba(170, 170, 204, 0.20) inset, -5px -5px 10px 0px rgba(255, 255, 255, 0.50) inset;

  
	// height: auto;
	background-color:#F5F5FA;

  ;
}
.talktext p{
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}
